@import 'styles/variables';
@import 'styles/mixins';

.filterContainer {
  @include flex(row, normal, flex-end, wrap);
  margin-top: 8px;

  @include b('mobile') {
    width: 70%;
    min-width: 333px;
    margin: auto;
    justify-content: flex-start;
  }

  @include b('extramobile') {
    width: 100%;
    min-width: auto;
  }

  .filterMode {
    @include flex(row, flex-end, normal, nowrap);
    margin-top: 22px;

    @include b('mobile') {
      width: 100%;
    }

    .item {
      cursor: pointer;
      color: $text-label-dark;
      white-space: nowrap;

      .itemIndicator {
        margin-top: 11px;
      }
    }

    .itemIndicator {
      margin-top: 12px;
      height: 1px;
      width: 100%;
      margin-bottom: 1px;
      background: $obsidianShard;

      @include light {
        background-color: $whisper;
      }
    }

    .itemActive {
      @include flex(column, normal, space-between, nowrap);
      height: 100%;
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;

      .itemIndicator {
        margin-top: 11px;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: $bg-accent;
      }
    }

    .space {
      min-width: 37px;
      width: 100%;

      @include b('mobile') {
        max-width: 37px;
        width: 100%;
      }
    }

    .spaceMobile {
      display: none;

      @include b('mobile') {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .stakedOnlyContainer {
    @include flex(row, center, normal, nowrap);
    margin-left: auto;
    margin-top: 22px;

    @include b('mobile') {
      margin-left: 0;
      order: 10;
      min-height: 30px;
    }

    .label {
      white-space: nowrap;
      margin-right: 20px;
    }

    .switcher {
      background: #3a3a3c;
      border-radius: 40px;
      height: 100%;
      max-height: 30px;
      width: 51px;

      @include light {
        background: #ddd;
      }

      padding: 2px;
      cursor: pointer;

      .flag {
        height: 100%;
        max-height: 26px;
        width: 100%;
        max-width: 26px;
        background-color: $bg-accent;
        border-radius: 14px;
        transition: 0.2s;

        @include light {
          background: #ffffff;
          box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
            0 3px 1px rgba(0, 0, 0, 0.03);
          border-radius: 100px;
        }
      }

      .activeFlag {
        transition: 0.2s;
        transform: translateX(21px);
      }
    }
  }

  .sortedBy {
    @include flex(row, center, normal);
    margin-left: 20px;
    margin-top: 22px;

    @include b('mobile') {
      margin-left: 0;
    }

    .label {
      white-space: nowrap;
      margin-right: 20px;

      @include b('mobile') {
        margin-right: 14px;
      }
    }

    .selector {
      width: 100%;
      min-width: 145px;
    }
  }

  .viewMode {
    width: 95px;
    height: 36px;
    background: $bg-project-card-dark;
    border-radius: 18px;
    margin-top: 22px;
    margin-left: 20px;
    @include flex(row, center, center, nowrap);

    @include b('extramobile') {
      margin-left: 0;
      margin-right: 25px;
    }

    @include light {
      background: $bg-icon-light;
    }

    .iconMode {
      cursor: pointer;

      @include light {
        path {
          stroke: #244b57;
        }
      }
    }
    .disable {
      opacity: 0.4;
      transition: 0.2s;

      @include light {
        path {
          stroke: #92939a;
        }
      }
    }

    .delimiter {
      height: 24px;
      width: 1px;
      background-color: rgba($color: white, $alpha: 0.2);
      margin: 0 10px;

      @include light {
        background-color: #b7b7b7;
        opacity: 0.2;
      }
    }
  }
}

.resultContainer {
  @include flex(row, normal, flex-start, wrap);
  width: calc(100% + 30px);
  margin-top: 30px;
  margin-bottom: 90px;
}
