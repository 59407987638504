@import '../../../styles/variables';
@import '../../../styles/mixins';

.faq_wrapper {
  width: 100%;
  margin-bottom: 144px;

  .title {
    @include text-common(xl, 600);
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
  }

  .faq_container {
    width: 100%;
    columns: 2;
    column-gap: 30px;

    .empty_box {
      page-break-inside: avoid;
      break-inside: avoid;
      width: 100%;
      min-height: 66px;
    }
    .question {
      page-break-inside: avoid;
      break-inside: avoid;

      width: 100%;
      border-radius: 15px;
      margin-bottom: 10px;
      overflow: hidden;
      transition: background-color 0.3s ease;
      @include dark {
        background-color: $bg-landing-box-dark;
      }
      @include light {
        background-color: $bg-main-light;
        box-shadow: $project-card-box-shadow;
      }

      .question_wrapper {
        padding: 20px 18px 20px 20px;
        cursor: pointer;
        @include flex(row, center, space-between);
        span {
          @include text-common(smd);
        }
        .arrow {
          transition: all 0.3s;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .answer {
        @include text-common(smd);
        @include description;
        padding: 0 35px 35px;
        width: 100%;
        @include dark {
          background-color: $bg-landing-box-active-dark;
        }
        @include light {
          background-color: $bg-landing-box-active-light;
          box-shadow: $project-card-box-shadow;
        }
      }

      &.active {
        @include dark {
          background-color: $bg-landing-box-active-dark;
        }
        @include light {
          background-color: $bg-landing-box-active-light;
          box-shadow: none;
        }
        .arrow {
          transform: rotate(180deg);
          path {
            @include dark {
              stroke: $text-accent-dark;
            }
            @include light {
              stroke: $text-accent-light;
            }
          }
        }
      }
    }
  }
}
:global(.faq_show) {
  &-enter {
    max-height: 0;
    opacity: 0;
  }
  &-enter-active {
    padding-top: 50px;
    max-height: 2000px;
    opacity: 1;
    transition: padding 300ms, max-height 300ms, opacity 500ms;
  }
  &-exit {
    padding-top: 50px;
    max-height: 2000px;
    opacity: 1;
  }
  &-exit-active {
    padding-top: 0;
    opacity: 0;
    max-height: 0;
    transition: padding 300ms, max-height 180ms, opacity 300ms;
  }
}
