@import 'styles/variables';
@import 'styles/mixins';

.p_claim {
  @include flex(row, center);
  gap: 10px 30px;
  @include b('mobile') {
    gap: 15px 0;
  }
  &__item {
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 255px;
    @include box(blue);
    @include b('tablet') {
      max-width: 357px;
    }
    @include b('mobile') {
      padding: 30px 20px;
    }
    &__title {
      color: $text-label-dark;
    }
    &__content {
      margin: 5px 0 15px;
      white-space: nowrap;
    }
    &__btn {
      width: 100%;
      max-width: 195px;
      margin: 0 auto;
      @include b('mobile') {
        max-width: 100%;
      }
    }
  }
}
