@import 'media';

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $format: 'woff') {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    @if $format == 'woff' {
      src: url('#{$file-path}.#{$format}') format($format);
    } @else {
      src: url('#{$file-path}.#{$format}') format('truetype');
    }
  }
}

@mixin flex($direction: row, $alignment: normal, $justification: normal, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
  flex-wrap: $wrap;
}

@mixin grid($columns: repeat(2, 1fr), $rows: 1fr, $gap: 10px) {
  display: grid;
  grid-template-columns: $columns;
  grid-auto-rows: $rows;
  gap: $gap;
}

@mixin font-asap {
  font-family: 'Asap', sans-serif;
}

@mixin font-lato {
  font-family: 'Lato', sans-serif;
}

@mixin dark {
  @at-root :global(.dark) & {
    @content;
  }
}

@mixin light {
  @at-root :global(.light) & {
    @content;
  }
}

@mixin box($type, $border-radius: 15px) {
  border-radius: $border-radius;
  border: 2px solid transparent;
  @if $type==blue {
    background: $bg-project-card-dark;
    @include light {
      background: #ffffff;
      box-shadow: 0 7px 27px rgba(58, 104, 124, 0.09);
    }
  }
  @if $type==blue-light-gray {
    background: $bg-project-card-dark;
    @include light {
      background: $bg-box-dark-blue-light;
    }
  }
  @if $type==blue-light {
    background: $bg-box-blue-light;
    @include light {
      background: #ffffff;
      box-shadow: 0 7px 27px rgba(58, 104, 124, 0.09);
    }
  }
  @if $type==dark-border {
    background: $bg-deep-dark;
    border-color: $border-deep-dark-box;
    @include light {
      background: $bg-box-dark-blue-light;
      border-color: $bg-box-dark-blue-light;
    }
  }
  @if $type==dark {
    background: $bg-deep-dark;
    @include light {
      background: $bg-box-dark-blue-light;
    }
  }
  @if $type==dark-blue-card {
    background-color: $bg-main-dark;
    @include light {
      background-color: $bg-main-light;
      box-shadow: $project-card-box-shadow;
    }
  }
}

@mixin text-control(
  $size: 14px,
  $height: 21px,
  $weight: normal,
  $color-dark: #ffffff,
  $color-light: #244b57
) {
  font-size: $size;
  font-weight: $weight;
  line-height: $height;
  @include dark {
    color: $color-dark;
  }
  @include light {
    color: $color-light;
  }
}
@mixin text-common($point, $weight: normal) {
  @if $point==ssm {
    font-size: 11px;
    line-height: 16px;
  }
  @if $point==sm {
    font-size: 12px;
    line-height: 18px;
  }
  @if $point==smd {
    font-size: 14px;
    line-height: 21px;
  }
  @if $point==md {
    font-size: 16px;
    line-height: 24px;
  }
  @if $point==lmd {
    font-size: 18px;
    line-height: 27px;
  }
  @if $point==slg {
    font-size: 24px;
    line-height: 36px;
    @include b('tablet') {
      font-size: 18px;
      line-height: 27px;
    }
  }
  @if $point==xl {
    font-size: 28px;
    line-height: 42px;
  }
  @if $point==xxl {
    font-size: 36px;
    line-height: 54px;
    @include b('mobile') {
      font-size: 24px;
      line-height: 29px;
    }
  }
  font-weight: $weight;
}
@mixin description {
  @include font-asap;
  @include dark {
    color: $text-label-dark;
  }
  @include light {
    color: $text-label-light;
  }
}
@mixin ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
