@import 'styles/mixins';
@import 'styles/variables';

.modal {
  padding: 40px 30px 32px;
  width: 100%;
  max-width: 460px;

  @include b('extramobile') {
    width: calc(100% - 40px);
    padding: 40px 20px 28px;
  }

  .title {
    text-align: center;
  }

  .description {
    text-align: center;
    color: #969696;
    margin-top: 10px;
  }

  .confirm {
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .item {
    @include flex(row, normal, normal, wrap);
    margin-top: 10px;

    @include light {
      color: $text-primary-dark;
    }
    @include dark {
      color: $text-footer-description-light;
    }

    .itemArrow {
      margin-right: 10px;
    }
  }

  .control {
    @include flex(row, normal, space-between, nowrap);
    margin-top: 40px;

    @include b('extramobile') {
      flex-direction: column-reverse;
    }

    .disagree {
      width: 100%;
      margin-right: 10px;

      @include b('extramobile') {
        margin-top: 10px;
      }
    }

    .agree {
      width: 100%;
    }
  }
}
