@import '../../styles/variables';
@import '../../styles/mixins';

.filter_wrapper {
  @include flex(row, center, center, wrap);
  gap: 40px;
  width: 100%;
  padding: 14px 0;
  margin-bottom: 16px;
  @include b(tablet) {
    @include flex(row, center, flex-start, wrap);
  }

  .filter_item {
    @include flex(row, center, center, nowrap);
    @include b(tablet) {
      margin-right: 40px;
      margin-bottom: 26px;
    }

    .label {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.03em;
      white-space: nowrap;
      margin-right: 15px;
      @include b(tablet) {
        margin-right: 10px;
      }
      @include dark {
        color: $text-label-dark;
      }
      @include light {
        color: $text-label-light;
      }
    }
    &:nth-of-type(1) {
      .select_inner_wrapper {
        min-width: 121px;
      }
    }
    &:nth-of-type(2) {
      .select_inner_wrapper {
        min-width: 102px;
      }
    }
    &:nth-of-type(3) {
      .select_inner_wrapper {
        min-width: 113px;
      }
    }
    &:nth-of-type(4) {
      .select_inner_wrapper {
        min-width: 145px;
      }
    }
  }
}
