@import 'styles/mixins/';

.p_rec {
  &__title {
    margin-bottom: 25px;
  }
  &__box {
    overflow: visible !important;
  }
  &__item {
    // width: fit-content !important;
    // @include b('mobile') {
    //   width: 335px !important;
    // }
  }
}
