@import 'styles/mixins';
@import 'styles/variables';

.timer {
  position: relative;
  z-index: 10;
  @include flex(row, flex-start, center, nowrap);
  @include text-control(48px, 120%, 600, $text-accent-dark, $text-accent-light);
  .unit_wrapper {
    @include flex(column, center, center);
    min-width: 80px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    .unit_type {
      @include text-common(md);
      color: $text-tier-info;
    }
  }
  &.mini {
    @include text-control(22px, 29px, 600, $text-accent-dark, $text-accent-light);
    @include flex(row, flex-start, space-between, nowrap);
    max-width: 220px;
    @include b('tablet') {
      @include text-control(18px, 22px, 600, $text-accent-dark, $text-accent-light);
    }
    @include b('mobile') {
      @include text-control(14px, 17px, 600, $text-accent-dark, $text-accent-light);
    }
    .unit_wrapper {
      min-width: 45px;
      @include b('mobile') {
        min-width: 30px;
      }
    }
    .unit_type {
      @include text-common(sm);
      @include b('mobile') {
        font-size: 9px;
        line-height: 13px;
      }
    }
  }
  &.medium {
    @include font-asap;
    @include text-control(36px, 41px, 400, $text-accent-dark, $text-accent-light);
    justify-content: flex-start;
    .unit_wrapper {
      min-width: 0;
    }
    .unit_type {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
