@import 'styles/mixins';
@import 'styles/variables';

.sp_items {
  margin-top: 30px;
  &__title {
    @include text-common(md, bold);
    margin-bottom: 20px;
  }
}
