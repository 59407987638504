h1,
.h1 {
  font-size: 32px;
  line-height: 38px;
  @include b('mobile') {
    font-size: 24px;
    line-height: 29px;
  }
}

h2,
.h2 {
  font-size: 28px;
  line-height: 34px;
}

h3,
.h3 {
  font-size: 20px;
  line-height: 24px;
}

.text {
  &-ssm {
    font-size: 11px;
    line-height: 16px;
  }
  &-sm {
    font-size: 12px;
    line-height: 140%;
    &-mobile {
      @include b('mobile') {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }
  &-smd {
    font-size: 14px;
    line-height: 21px;
  }
  &-md {
    font-size: 16px;
    line-height: 24px;
    &-tablet {
      @include b('tablet') {
        font-size: 14px;
        line-height: 21px;
      }
    }
    &-extramobile {
      @include b('extramobile') {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  &-lmd {
    font-size: 18px;
    line-height: 27px;
  }
  &-slg {
    font-size: 24px;
    line-height: 36px;
    &-tablet {
      @include b('tablet') {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  &-bold {
    font-weight: 700;
  }
  &-600 {
    font-weight: 600;
  }
  &-upper {
    text-transform: uppercase;
  }
  &-capitalize {
    text-transform: capitalize;
  }
  &-green {
    color: $green;
  }
  &-gray {
    color: $text-label-dark;
  }
  &-red {
    color: $red;
  }
  &-white {
    color: #fff;
  }
  &-ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-italic {
    font-style: italic;
  }
}
.dark {
  .text {
    &-orange {
      color: $text-accent-dark;
    }
  }
}
.light {
  .text {
    &-orange {
      color: $text-accent-light;
    }
  }
}
