@import 'styles/mixins';
@import 'styles/variables';

.congrat_modal {
  padding: 100px 100px 60px;
  max-width: 625px;
  text-align: center;
  @include b('mobile') {
    padding: 60px 35px;
  }
  img {
    @include b('mobile') {
      max-width: 135px;
    }
  }
  &__title {
    @include text-common(xxl, bold);
    margin: 30px 0px 10px;
  }
  &__btn {
    margin: 30px auto 0;
  }
}
