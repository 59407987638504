@import 'src/styles/mixins';
@import 'src/styles/variables';

.p_controls {
  padding: 40px;
  width: 100%;
  @include b('tablet') {
    padding: 20px 20px 30px;
  }
  @include box(blue);
  &__head {
    @include flex(row, center);
    margin-bottom: 11px;
    &__img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      @include flex(row, center, center);
      @include b('tablet') {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
      img {
        width: 100%;

      }
    }
    &__status {
      margin-left: auto;
      &:last-of-type {
        margin-left: 10px;
      }
    }
    &__name {
      max-width: 385px;
    }
    &__tags {
      @include flex(row, center);
      margin-top: 3px;
      &__item {
        color: inherit;
        position: relative;
        @include flex(row, center);
        & + & {
          margin-left: 34px;
          @include b('tablet') {
            margin-left: 20px;
          }
          &::before {
            content: '';
            height: 4px;
            width: 4px;
            background: #57599f;
            position: absolute;
            left: -19px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            border-radius: 50%;
            @include b('tablet') {
              left: -12px;
            }
          }
        }
        &__address {
          cursor: pointer;
        }
      }
    }
  }
  &__price {
    margin-top: 30px;
    @include b('mobile') {
      margin-top: 20px;
    }
  }
  &__presale {
    margin-top: 25px;
  }
  &__info {
    @include flex(row, flex-start, space-between);
    width: 100%;
    margin-bottom: 30px;
    @include b(1200) {
      flex-direction: column;
    }
    .divider {
      background: #3A3B63;
      border-radius: 15px;
      height: 4px;
      width: 100%;
      margin-bottom: 10px;
    }
    &__item {
      @include b(1200) {
        width: 100%;
      }
      width: calc(33% - 20px);
      & + & {
        margin-left: 20px;
        @include b(1200) {
          margin-left: 0;
          margin-top: 10px;
        }
      }
      &__title {
        margin-bottom: 5px;
        &_mt {
          margin-top: 10px;
        }
        &_mb {
          margin-bottom: 10px;
        }
        &_align {
          text-align: right;
        }
      }
      &__content {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_lg, &_full {
        width: 50%;
        &__content {
          @include font-asap;
          @include text-control(36px, 41px, 400, $text-accent-dark, $text-accent-light);
        }
      }
      &_full {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  &__voting {
    margin-top: 25px;
  }
  &__verify {
    background: rgba(59, 65, 181, 0.18);
    border: 1px solid $purple;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    color: $text-purple;
    text-align: center;
    margin-top: 20px;
    @include light {
      background: rgba(59, 65, 181, 0.05);
    }
  }
  &__lottery {
    margin-top: 11px;
    font-size: 14px;
    line-height: 21px;
    color: $color-green;
  }
  &__progress {
    margin-top: 30px;
    margin-bottom: 25px;
    &__title {
      span:first-of-type {
        font-weight: 600;
        color: $text-accent-dark;
        @include light {
          color: $text-accent-light;
        }
      }
      &.failed, &.fail {
        span:first-of-type {
          color: #FF8484;
        }
      }
      &.success {
        span:first-of-type {
          color: #87FFB0;
        }
      }
    }
    &__goal {
      margin-top: 15px;
      @include flex(row, center);
      &__raised {
        @include flex(row, center);
        margin-right: 20px;
        img {
          margin-right: 10px;
          width: 28px;
          @include b('tablet') {
            width: 24px;
          }
        }
      }
    }
  }
  &__btn {
    width: 100%;
    margin-top: 27px;
    svg {
      margin-right: 12px;
    }
    &:disabled {
      background: #92939A !important;
      color: #11333E !important;
    }
    &_vote {
      margin-left: 10px;
    }
  }
  &__btns {
    @include flex(row, center, space-between, nowrap);
    &__text {
      margin-top: 10px;
    }
  }
  &__timer {
    margin-top: 20px;
    &__text {
      margin-bottom: 10px;
    }
  }
  &__socials {
    margin: 25px 0 27px;
    &__box {
      @include flex(row, center);
      margin-top: 10px;
    }
    &__item {
      width: 36px;
      height: 36px;
      @include flex(row, center, center);
      border-radius: 50%;
      background: $bg-socials-icon-dark;
      @include light {
        background-color: $bg-box-dark-blue-light;
      }
      & + & {
        margin-left: 10px;
      }
    }
  }
}

.claim_info {
  @include flex(row, center);
  flex-wrap: wrap;
  width: 100%;
}
