@import 'styles/mixins';
@import 'styles/variables';

.empty {
  max-width: 300px;
  margin: 100px auto 0;
  text-align: center;
  @include b('mobile') {
    margin-top: 50px;
  }
  img {
    @include b('mobile') {
      max-width: 125px;
    }
  }
  &__title {
    @include text-common(slg, bold);
  }
  &__text {
    @include text-common(md);
  }
}
