@import 'src/styles/variables';
@import 'src/styles/mixins/index';

.modal_wrapper {
  width: 100%;
  max-width: 419px;
  @include b(extramobile) {
    width: 335px;
  }
}

.buy_quack_wrapper {
  padding: 40px 8px 60px 30px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 30px;
    text-align: center;
    @include dark {
      color: $text-primary-dark;
    }
    @include light {
      color: $text-primary-light;
    }
  }

  .exchanges_wrapper {
    @include flex(column, center, center);
    width: 100%;

    .exchange {
      @include flex(row, center, normal, nowrap);
      width: 100%;

      @include font-lato();
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;

      cursor: pointer;

      .icon {
        margin-left: auto;
      }
    }
    .exchange_item {
      width: calc(100% - 17px);
      padding: 12px 14px 12px 20px;
    }
  }
  :global(.ScrollbarsCustom-Content) {
    padding: 0 !important;
  }

  .trackY {
    top: 0 !important;
    height: calc(100% - 10px) !important;
    width: 5px !important;
    background: #1f2149 !important;
    border-radius: 10px !important;
    &.light {
      background: $text-steps-light !important;
    }
  }
  .thumbY {
    background: #525489 !important;
    width: 5px !important;
    border-radius: 10px !important;
    &.light {
      background: $text-input-placeholder-light !important;
    }
  }
}
.btn {
  margin-top: 40px;
}
