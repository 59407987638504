@import 'styles/variables';
@import 'styles/mixins';

.modal_wrapper {
  width: 100%;
  max-width: 552px;
  @include b(mobile) {
    max-width: 335px;
  }
}

.user_modal_wrapper {
  padding: 40px 74px 50px 68px;
  @include flex(column, center);
  @include b(mobile) {
    padding: 40px 20px 31px;
  }

  .title {
    @include text-control(24px, 120%, 700);
    margin-bottom: 30px;
  }

  .info_wrapper {
    @include flex(row, center, space-between);
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    @include b(mobile) {
      @include flex(column);
    }

    .info_box {
      width: calc((100% - 10px) / 2);
      padding: 30px 15px 32px;
      background: $bg-user-info-box;
      border-radius: 10px;
      @include flex(column, center);
      @include b(mobile) {
        width: 100%;
      }
      @include light {
        background: $bg-user-info-box-light;
      }

      .label {
        @include text-control(12px, 120%, 400);
        margin-bottom: 10px;
      }
      .content {
        @include text-common(smd, 600);
        text-align: center;
        //white-space: nowrap;
      }
    }
  }

  .wallet {
    @include flex(row, center, center, nowrap);
    background-color: $bg-accent-dark;
    border-radius: 29.6284px;
    padding: 11px 16px;
    margin-bottom: 20px;
    @include light {
      background-color: $bg-accent-light;
    }

    .address {
      margin-right: 5px;
      @include text-common(smd, 500);
      text-transform: lowercase;
      color: $bg-main-dark;
      @include b(mobile) {
        display: none;
      }
      &_sm {
        margin-right: 5px;
        @include text-common(smd, 500);
        text-transform: lowercase;
        color: $bg-main-dark;
        display: none;
        @include b(mobile) {
          display: block;
        }
      }
    }

    img {
      filter: $svg-filter-copy;
    }
  }
  .disconnect_btn {
    padding: 12px 45px;
    @include text-common(sm, 600);
  }
}
