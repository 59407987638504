@import '../../styles/variables';
@import '../../styles/mixins';

.sidebar_wrapper {
  display: block;
  position: fixed;
  width: 84px;
  height: 100vh;
  z-index: 20;
  &.active {
    width: 246px;
  }
  @include b(tablet) {
    display: none;
    top: 0;
    padding-top: 80px;
    &.active {
      display: block;
    }
  }
  @include dark {
    background-color: $bg-main-dark;
  }
  @include light {
    background-color: $bg-main-light;
  }

  .sidebar_menu {
    padding: 50px 20px 0 30px;

    .menu {
      @include flex(column);
    }
  }

  .theme_toggle_wrapper {
    padding-left: 30px;
    margin-top: 60px;
    @include flex(row, center);
    .theme_value {
      margin-left: 15px;
      @include text-control(13px, 140%, 600, $text-label-dark, $text-label-light);
      letter-spacing: 0.03em;
      text-transform: capitalize;
    }
  }
}
.sidebar_cover {
  display: none;
  @include b(tablet) {
    position: fixed;
    width: 200vh;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);

    display: none;
    &.active {
      display: block;
    }
  }
}
