@import '../../styles/variables';
@import '../../styles/mixins';

.header_wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: 0.3s;
  @include dark {
    background: $bg-main-dark;
  }
  @include light {
    background: $bg-main-light;
  }
  &_active {
    box-shadow: $header-shadow;
  }
}
.container {
  padding: 0 40px 0 35px;
  height: 80px;

  @include flex(row, center);
  @include b(tablet) {
    @include flex(row, center, space-between, nowrap);
  }
  @include b('mobile') {
    padding: 10px 20px;
    height: unset;
  }

  .burger_wrapper {
    display: none;
    @include b(tablet) {
      display: block;
      width: 33%;
    }
  }
  .burger {
    width: 40px;
    height: 40px;
  }

  .logo_wrapper {
    @include flex(row, center);
    transition: 0.3s;
    height: 80px;
    visibility: visible;
    opacity: 1;
    @include b(tablet) {
      position: absolute;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      @include flex(row, center, center);
    }
    .menu_button {
      margin-right: 34px;
      cursor: pointer;
      @include b(tablet) {
        display: none;
      }
    }
    &_hidden {
      @include b('mobile') {
        visibility: hidden;
        opacity: 0;
      }
    }

    .logo {
      height: 80px;
      @include flex(row, center, center);
      @include dark {
        color: $text-primary-dark;
      }
      @include light {
        color: $text-primary-light;
      }
      &_text {
        margin-left: 8px;
      }
    }
  }
}

.search {
  margin-left: 102px;
  width: 100%;
  max-width: 500px;
  @include b(tablet) {
    max-width: 400px;
    margin-left: 0;
  }
}
