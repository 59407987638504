@import '/src/styles/mixins';
@import '/src/styles/variables';

.p_type {
  padding-bottom: 100px;
  &__title {
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    margin: 10px 0 50px;
  }
  &__btn {
    margin-top: 40px;
    margin-left: auto;
  }
  &__box {
    @include grid(repeat(3, 284px), auto);
    justify-content: space-between;
    padding: 0 90px;
    @include b(tablet) {
      padding: 0;
      @include grid(repeat(3, 228px), auto);
    }
    @include b(mobile) {
      @include grid(repeat(1, 100%), auto);
    }
  }
  &__item {
    height: 100%;
    & + & {
      margin-top: 0;
    }
    &__content {
      text-align: center;
      padding: 30px 0 25px;
      @include b(tablet) {
        padding: 30px 0 0;
      }
    }
    &__title {
      margin: 30px 0 5px;
      @include text-common(lmd, bold);
    }
    &__text {
      @include text-common(sm);
    }
  }
}
