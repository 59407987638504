@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  @include flex(row, center);
  height: 4px;
  width: 100%;
  border-radius: 15px;
  @include dark {
    background-color: $bg-progress-dark;
  }
  @include light {
    background-color: $bg-progress-light;
  }
  &.lite {
    height: 3px;
    .filler {
      height: 3px;
    }
  }
}

.title {
  @include flex(row, center, space-between);
  margin-bottom: 10px;
}

.filler {
  height: 5px;
  border-radius: inherit;
  text-align: right;
  &.accent {
    @include dark {
      background-color: $text-accent-dark;
    }
    @include light {
      background-color: $text-accent-light;
    }
  }
  &.red {
    background-color: $color-red;
  }
  &.yellow {
    background-color: $color-yellow;
  }
  &.green {
    background-color: $color-green;
  }
}
