$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

// Mobile first
$mediaWidthLaptop: 1440px;
$mediaWidthDesktop: 1024px;
$mediaWidthTablet: 768px;
$mediaWidthMobile: 550px;

$green: #53da58;
$red: #ff5649;
$purple: #7f6ef4;
$deepKoamaru: #32345c;
$obsidianShard: #04051b;
$whisper: #e7e7e7;

$header-shadow: 0 0 5px rgba(#393556, 0.2);

// BG
$bg-main-light: #ffffff;
$bg-main-dark: #0f1030;
$bg-secondary-dark: #0b0b24;
$bg-secondary-light: #fbfbfc;
$bg-deep-dark: #06071b;
$bg-deep-light: #fafafa;
$bg-box-dark-blue-light: #f4f5f8;
$bg-box-blue-light: #1f1f4b;
$bg-accent: #ffbb0b;
$bg-accent-dark: #ffbb0b;
$bg-accent-light: #ffde8a;
$bg-accent-light: #ff9900;
$bg-footer-dark: #0b0b24;
$bg-footer-light: #fbfbfc;
$bg-gradient-dark: linear-gradient(93.73deg, #141652 23.76%, rgba(20, 22, 82, 0) 91.08%);
$bg-gradient-light: linear-gradient(93.73deg, #fffbe4 23.76%, #ffffff 91.08%);
$bg-select-dark: #23254c;
$bg-select-light: #f6f6f6;
$bg-select-active-dark: #565bb7;
$bg-select-active-light: #cadfff;
$bg-project-card-dark: #14163c;
$bg-project-card-light: #ffffff;
$bg-project-status-dark: #232559;
$bg-project-status-light: #ffffff;
$bg-progress-dark: #3a3b63;
$bg-progress-light: #efefef;
$bg-wallet-btn-dark: #06071b;
$bg-wallet-btn-light: #fafafa;
$bg-landing-dark: #14153b;
$bg-landing-light: #14153b;
$bg-icon-dark: #140e59;
$bg-icon-light: #f6f6f6;
$bg-tales-dark: #080930;
$bg-tales-light: #fbfbfc;
$bg-landing-container-dark: #05072f;
$bg-landing-container-light: #ffffff;
$bg-map-dark: #05062f;
$bg-lotte-dark: #272a5b;
$bg-lotte-light: #f4f5f8;
$bg-steps-circle-dark: #414372;
$bg-steps-circle-active-dark: #ffbb0b;
$bg-steps-circle-small-active-dark: #ffe6a6;
$bg-landing-box-dark: #05062f;
$bg-landing-box-active-dark: #121341;
$bg-landing-box-active-light: #fbfbfc;
$bg-select-gray: #e3e3e3;
$bg-status: #3b41b5;
$bg-tip-dark: #2f3173;
$bg-tip-light: #ffffff;
$bg-requirements-box-dark: #11111d;
$bg-socials-icon-dark: #2c2d6d;
$bg-user-info-box: #181A43;
$bg-user-info-box-light: #FAFAFA;

// TEXT
$text-steps-dark: rgba(255, 255, 255, 0.5);
$text-steps-light: #e3e3e3;
$text-steps-active-light: #183f4b;
$text-primary-light: #244b57;
$text-primary-dark: #ffffff;
$text-color-subtitle-dark: #92939a;
$text-color-subtitle-light: #9494ac;
$text-input-placeholder-dark: #6e6f9c;
$text-input-placeholder-light: #9ea4b7;
$text-accent-dark: #ffbb0b;
$text-accent-light: #ff9900;
$text-menu-link: #92939a;
$text-footer-title: #92939a;
$text-footer-description-dark: #fcfcfc;
$text-footer-description-light: #183f4b;
$text-label-dark: #9697a9;
$text-label-light: #9494ac;
$text-purple: #8e7eff;

$text-project-description-dark: #869fb6;
$text-landing-dark: #a3a4dd;
$text-landing-light: #8f8f8f;
$text-landing-map-dark: #18194e;
$text-landing-map-light: #f1f1f7;
$text-letters-dark: #11123a;
$text-letters-light: #fbfbfc;

$text-tier-info: #969696;
$text-link-dark: #11333e;

// TABLET
$head-color-dark: $text-label-dark;
$head-color-light: $text-label-light;
$item-bg-light: #f9f9f9;
$item-bg-dark: #14163b;

// BORDERS
$border-search-dark: 1px solid rgba(46, 47, 100, 0.8);
$border-search-light: 1px solid rgba(46, 47, 100, 0.15);
$border-connect-dark: 2px solid #24265e;
$border-connect-light: 2px solid #babbd3;
$border-deep-dark-box: #24265d;

//OTHER
$svg-filter-color-accent: brightness(0) saturate(100%) invert(87%) sepia(36%) saturate(5856%)
  hue-rotate(348deg) brightness(108%) contrast(110%);
$svg-filter-color-primary-dark: brightness(0) saturate(100%) invert(23%) sepia(49%) saturate(497%)
  hue-rotate(148deg) brightness(96%) contrast(90%);
$svg-filter-copy: brightness(0) saturate(100%) invert(5%) sepia(99%) saturate(1693%)
  hue-rotate(226deg) brightness(86%) contrast(98%);
$svg-filter-dropdown: brightness(0) saturate(100%) invert(9%) sepia(22%) saturate(2564%)
  hue-rotate(205deg) brightness(97%) contrast(104%);
$burger-color: #92939a;
$project-card-box-shadow: 0 7px 27px rgba(58, 104, 124, 0.09);
$divider-color-dark: #35386a;
$divider-color-light: #d7d8e4;
$bg-select-shadow: 0 0 20px rgba(190, 192, 249, 0.42);

$color-red: #ff5649;
$color-yellow: #ffc452;
$color-green: #53da58;

$dot-color: #57599f;
