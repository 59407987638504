@import '/src/styles/mixins';
@import '/src/styles/variables';

.cp_form {
  padding-bottom: 100px;
  &__container {
    //overflow: hidden;
    max-width: 920px;
    width: 100%;
    @include box(blue, 16px);
  }
  &__preview {
    padding: 74px 20px;
    background-image: url('../../assets/img/start_project/form-preview.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    @include b('mobile') {
      padding: 32px;
    }
    &__text {
      text-align: center;
      @include text-common(xxl, bold);
      @include b('mobile') {
        @include text-common(slg, bold);
      }
      color: $text-primary-dark;
      //@include light {
      //  color: $text-primary-dark;
      //}
    }
  }
  &__content {
    padding: 40px 61px 64px;
    @include b('mobile') {
      padding: 35px 20px 40px;
    }
  }
  &__form {
    margin-top: 104px;
    @include b(extramobile) {
      margin-top: 0;
    }
  }
}
