@import '../../styles/mixins';

.voting {
  padding-bottom: 50px;
  width: 100%;
  @include b(tablet) {
    padding: 0 0 50px;
  }
  &__title {
    margin-top: 20px;
  }
  &__search {
    max-width: 445px;
    margin: 15px 0 20px;
  }
  .pagination {
    margin-top: 30px;
  }
}
