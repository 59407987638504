@import '../../../styles/variables';
@import '../../../styles/mixins';

.tokenomics_wrapper {
  position: relative;
  width: 100%;
  padding: 120px 0;
  @include dark {
    background-color: $bg-tales-dark;
  }
  @include light {
    background-color: $bg-tales-light;
  }
  .bg {
    position: absolute;
    bottom: 100%;
    right: 97px;
    img {
      width: 114%;
    }
  }

  .title {
    width: 100%;
    text-align: center;
    @include text-common(xl, 600);
    margin-bottom: 40px;
  }

  .swiper {
    padding-bottom: 60px;
    overflow: visible;
    .swiper_item {
      width: 100%;
      height: 363px;
      padding: 77px 30px 50px;
      @include box(blue);
      img {
        margin-bottom: 20px;
        @include light {
          filter: $svg-filter-color-accent;
        }
      }
      .title {
        @include text-control(20px, 30px, 600);
        text-align: left;
        margin-bottom: 10px;
      }
      .description {
        @include text-common(md);
        @include description;
      }
    }

    :global(.swiper-slide) {
      width: 255px;
    }
    :global(.swiper-pagination-bullet) {
      width: 10px;
      height: 10px;
      background-color: #333579;
      &-active {
        @include dark {
          background-color: $bg-accent-dark;
        }
        @include light {
          background-color: $bg-accent-light;
        }
      }
    }
  }
}
