@import '/src/styles/mixins';
@import '/src/styles/variables';

.steps {
  @include flex(row, center, center, nowrap);
  @include b(extramobile) {
    display: none;
  }
}
.item {
  position: relative;
  @include flex(column, center);
  @include text-common(smd);
  color: $text-steps-dark;
  @include light {
    color: $text-steps-light;
  }
  & + & {
    margin-left: 70px;
    @include b('mobile') {
      margin-left: 50px;
    }
  }
  &_active {
    color: #fff;
    @include light {
      color: $text-steps-active-light;
    }
  }
  &__circle {
    border-radius: 50%;
    background: $bg-steps-circle-dark;
    position: relative;
    width: 12px;
    height: 12px;
    margin-bottom: 15px;
    @include flex(row, center, center);
    @include light {
      background: $text-steps-light;
    }
    @include b('mobile') {
      width: 12px;
      height: 12px;
    }
  }
  &_active &__circle {
    background: $bg-steps-circle-active-dark;
    box-shadow: 0 1px 11px #ffcc47;
    &::before {
      border-radius: 50%;
      content: '';
      width: 6px;
      height: 6px;
      background: $bg-steps-circle-small-active-dark;
      display: block;
    }
  }
  &:not(:last-child) &__circle {
    &::after {
      content: '';
      height: 2px;
      width: 70px;
      left: 100%;
      top: 50%;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      display: block;
      background: $bg-steps-circle-dark;
      @include light {
        background: $text-steps-light;
      }
      @include b('mobile') {
        width: 50px;
      }
    }
  }
  &_active:not(:last-child) &__circle {
    &::after {
      background: $bg-steps-circle-active-dark;
    }
  }
  &__label {
    position: absolute;
    width: 70px;
    top: 30px;
    @include text-control(12px, 120%, normal, rgba(255, 255, 255, 0.5), rgba(150, 150, 150, 0.5));
    text-align: center;
    overflow-wrap: break-word;
  }
  &_active &__label {
    color: $text-primary-dark;
    @include light {
      color: $text-primary-light;
    }
  }
}
