@import 'styles/mixins';
@import 'styles/variables';

.pdd_form {
  &__row {
    & + & {
      margin-top: 30px;
    }
    &__title {
      padding-left: 10px;
      @include text-common(slg, bold);
      margin-bottom: 5px;
    }
  }
}
