.sp_form {
  &__row {
    margin-top: 31px !important;
    &_sm {
      margin-top: 16px !important;
    }
  }
  &__btns {
    margin-top: 48px !important;
  }
}
