@import 'styles/variables';
@import 'styles/mixins';

$color-presale: #ffbb0b;
$color-liquidity: #bf21d9;
$color-fees: #5517da;
$color-locked: #25e1d6;
$color-unlocked: #ff5c00;

.chart_wrapper {
  @include flex(row, center, flex-start, nowrap);
  width: 100%;
  margin-bottom: 35px;

  @include b(tablet) {
    @include flex(column);
  }

  .chart {
    position: relative;
    margin-right: 71px;
    width: 158px;
    height: 158px;
    transform: scale(-1, 1);
    @include b(tablet) {
      margin-bottom: 22px;
    }

    .chart_circle {
      position: absolute;

      &.presale {
        width: 158px;
        height: 158px;
        path {
          &:nth-child(2) {
            stroke: $color-presale !important;
          }
        }
      }
      &.liquidity {
        left: 18px;
        top: 18px;
        width: 122px;
        height: 122px;
        path {
          &:nth-child(2) {
            stroke: $color-liquidity !important;
          }
        }
      }
      &.fees {
        left: 35px;
        top: 35px;
        width: 88px;
        height: 88px;
        path {
          &:nth-child(2) {
            stroke: $color-fees !important;
          }
        }
      }
      &.locked {
        left: 52px;
        top: 52px;
        width: 54px;
        height: 54px;
        path {
          &:nth-child(2) {
            stroke: $color-locked !important;
          }
        }
      }
      &.unlocked {
        left: 67px;
        top: 67px;
        width: 24px;
        height: 24px;
        path {
          &:nth-child(2) {
            stroke: $color-unlocked !important;
          }
        }
      }
    }
  }

  .legends {
    @include flex(column, flex-start, flex-start);
    max-height: 89px;
  }
}
.legend {
  @include text-common(smd, 500);
  text-transform: capitalize;
  margin-right: 53px;
  margin-bottom: 13px;
  color: $text-primary-dark;
  @include light {
    color: $text-primary-light;
  }
  &.dark {
    color: $text-primary-dark;
    background-color: $bg-tip-dark;
  }
  &.light {
    color: $text-primary-light;
    background-color: $bg-tip-light;
    box-shadow: $project-card-box-shadow;
  }
  &:nth-of-type(3n) {
    margin-bottom: 0;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 9px;
    border-radius: 50%;
  }
  &.presale {
    &::before {
      background-color: $color-presale;
    }
  }
  &.liquidity {
    &::before {
      background-color: $color-liquidity;
    }
  }
  &.fees {
    &::before {
      background-color: $color-fees;
    }
  }
  &.locked {
    &::before {
      background-color: $color-locked;
    }
  }
  &.unlocked {
    &::before {
      background-color: $color-unlocked;
    }
  }
  &.tooltip {
    margin: 0;
    padding: 6px 10px;
  }
}
:global(.chart-tooltip) {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  border-radius: 2px 10px 10px 10px;
  overflow: hidden;
  &-arrow {
    display: none;
  }
  &-inner {
    @include flex(row, center, center);
  }
}
