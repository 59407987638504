@import '../../../styles/variables';
@import '../../../styles/mixins/index';

.modal_wrapper {
  width: 100%;
  max-width: 409px;
  @include b(extramobile) {
    width: 335px;
  }
}

.wallet_connect_wrapper {
  padding: 40px 25px;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 30px;
    text-align: center;
    @include dark {
      color: $text-primary-dark;
    }
    @include light {
      color: $text-primary-light;
    }
  }

  .wallets_wrapper {
    @include flex(column, center, flex-start);
    width: 100%;
    //min-height: 460px;

    .wallet {
      @include flex(row, center, normal, nowrap);
      width: 100%;

      @include font-lato();
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;

      cursor: pointer;

      // @include dark {
      //   color: $text-primary-dark;
      //   background-color: $bg-secondary-dark;
      // }
      // @include light {
      //   color: $text-primary-light;
      //   background-color: $bg-secondary-light;
      // }

      .icon {
        margin-left: auto;
      }
    }
  }
}
.btn {
  margin-top: 50px;
  &_cancel {
    margin-top: 10px;
  }
}
