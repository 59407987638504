@import '/src/styles/mixins';
@import '/src/styles/variables';

.sp_empty {
  @include flex(column, center);
  margin-top: 50px;
  &__img {
    max-width: 500px;
    width: 100%;
  }
  &__title {
    @include text-common(xl, bold);
    text-align: center;
    max-width: 685px;
    margin-top: 11px;
    margin-bottom: 30px;
    @include b(tablet) {
      margin-top: 36px;
      font-size: 24px;
      line-height: 29px;
    }
    @include b(extramobile) {
      margin-top: 16px;
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__subtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }
  &__btn {
    @include b(extramobile) {
      width: 100%;
    }
  }
}
