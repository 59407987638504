@import '../../styles/variables';
@import '../../styles/mixins';

:global(.modal) {
  z-index: 100;
  overflow: hidden;

  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    height: 100%;

    background-color: #00000073;
  }

  &-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    @include flex(row, center, center);
    width: 100%;
    padding: 50px 0;

    overflow: auto;

    outline: 0;
  }
}
.modal_wrapper {
  position: relative;
  border-radius: 16px;
  &:global(.dark) {
    background-color: $bg-main-dark;
  }
  &:global(.light) {
    background-color: $bg-main-light;
  }
}
