@import 'styles/variables';
@import 'styles/mixins';

.pl_wrapper {
  width: 100%;
  margin-top: 15px;
  padding: 50px 35px 40px 40px;
  @include box(blue);

  @include b(tablet) {
    padding: 20px 20px 30px;
  }

  .pl_head {
    //width: 100%;
    margin-bottom: 36px;
    @include flex(row, center, space-between, nowrap);
    @include b(tablet) {
      @include flex(column);
    }
  }
  .title {
    @include text-common(lmd, 500);
    margin-bottom: 3px;
    @include b(tablet) {
      @include text-common(smd, 500);
    }
    span {
      color: $text-accent-dark;
      font-weight: 600;
      @include light {
        color: $text-accent-light;
      }
    }
  }
  .subtitle {
    @include text-control(12px, 18px, normal);
    @include description;
    @include flex(row, center);
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $dot-color;
      margin: 0 15px;
    }
  }
  .btn {
    padding: 11px 45.5px 11px 22.5px;
    position: relative;
    @include b(tablet) {
      margin-top: 15px;
      padding: 9px 42px 9px 19px;
      @include text-common(sm, 600);
      max-width: 145px;
    }
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      right: 22.5px;
      background: url('../../../assets/img/icons/lock_sm.svg') center no-repeat;
      background-size: 20px;
      @include b(tablet) {
        right: 19px;
      }
    }
  }

  .tab_content_wrapper {
    width: 100%;
  }
}
