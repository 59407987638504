@import '/src/styles/mixins';

.project {
  //padding-bottom: 100px;
  &__claim {
    margin: 20px 0 30px;
  }
  &__wrapper {
    @include flex();
  }
  &__content {
    @include flex(row, normal, normal, nowrap);
    margin-bottom: 100px;
    margin-top: 30px;
    @include b(tablet) {
      @include flex(column);
    }
  }
  &__box {
    max-width: 540px;
    width: 100%;
    @include b('1200') {
      max-width: 357px;
    }
    @include b('tablet') {
      max-width: 100%;
    }
  }
  &__box_controls {
    max-width: 540px;
    width: 100%;
    @include b('1200') {
      max-width: calc(100% - 357px);
    }
    @include b('tablet') {
      max-width: 100%;
      margin-left: 0;
      margin-top: 30px;
    }
    margin-left: 30px;
  }
}
