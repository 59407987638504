@import 'styles/mixins';
@import 'styles/variables';
.lsi {
  @include flex(row, flex-start, space-between, nowrap);
  margin-top: 30px;
  &:first-of-type {
    margin-top: 0;
  }
  @include b(mobile) {
    @include flex(column, flex-end);
  }
  //& + & {
  //  margin-top: 30px;
  //}
  &__input {
    &__prefix {
      padding-right: 20px;
      @include flex(row, center, flex-start, nowrap);
      @include b('mobile') {
        padding-right: 15px;
      }
      &__img {
        border-radius: 50%;
        width: 18px;
        height: 18px;
        overflow: hidden;
        margin: 0 10px 0 15px;
        @include b('mobile') {
          margin: 0 5px 0 10px;
        }
        img {
          max-width: 100%;
        }
      }
      &__btn {
        font-size: 14px !important;
        line-height: 21px !important;
        font-weight: 500;
        border-radius: 10px !important;
        @include b(mobile) {
          padding: 3px 8px !important;
        }
      }
      &__text {
        @include text-common(smd);
        color: #6E6F9C;
      }
    }
    &__subtitle_wrapper {
      @include flex(row);
    }
    &__subtitle {
      @include text-control(10px, 12px);
    }
    &__error {
      margin-top: 5px;
      @include text-control(10px, 12px);
      color: $red !important;
    }
  }
}
