@import '../../styles/mixins';
@import '../../styles/variables';

.title {
  margin-bottom: 6px;
  padding: 0 10px;
}
.wrapper {
  width: 100%;
  position: relative;
  height: fit-content;
}

.content {
  @include flex(row, center, space-between, nowrap);
  width: 100%;
  &_blue {
    @include box(blue-light-gray);
  }
  &_dark {
    @include box(dark, 10px);
  }
}

.input {
  width: 100%;
  background: transparent;
  border: none;
  padding: 13px 20px;
  color: white;
  @include text-common(smd);
  resize: none;
  &::placeholder {
    color: $text-input-placeholder-dark;
    @include light {
      color: $text-input-placeholder-light;
    }
  }
  @include light {
    color: $text-primary-light;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error {
  position: absolute;
  left: 10px;
  top: calc(100% + 5px);
  @include text-control(12px, 14px, normal, $red, $red);
}

.subtitle {
  padding: 0 10px;
  margin-top: 5px;
  @include text-control(12px, 140%);
}

.date {
  padding: 10px;
  height: 100%;
  cursor: pointer;
  @include light {
    path {
      stroke: #244b57;
    }
  }
}
.textarea {
  &__counter {
    position: absolute;
    bottom: 15px;
    right: 20px;
    @include text-common(sm);
    color: $text-input-placeholder-dark;
    @include light {
      color: $text-input-placeholder-light;
    }
  }
}
