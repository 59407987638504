@import '/src/styles/mixins';
@import '/src/styles/variables';

.v_item {
  display: block;
  &__img {
    max-width: 42px;
    max-height: 42px;
    img {
      //height: 100% !important;
      width: 100%;
      height: auto;
      display: block;
    }
  }
  &__name {
    min-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    span {
      max-width: 220px;
      @include b('tablet') {
        max-width: 180px;
      }
      @include b('mobile') {
        max-width: 70px;
      }
    }
  }
  &__voting {
    @include flex(row, center);
    &__item {
      @include flex(row, center);
      & + & {
        margin-left: 30px;
      }
      span {
        margin-left: 10px;
      }
      &__like {
        &__active {
          path {
            fill: $green;
          }
        }
      }
      &__dislike {
        &__active {
          path {
            fill: $red;
          }
        }
      }
    }

    &_failed {
      color: #FF8484;
      text-decoration: none;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  &__wrapper {
    @include b(mobile) {
      padding-left: 15px;
    }
  }
}
