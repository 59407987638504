@import 'styles/variables';
@import 'styles/mixins';

.audit {
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 10px;
  @include text-control(14px, 140%);

  &.success {
    background: rgba(59, 181, 93, 0.18);
    border: 1px solid #6ef48c;
    color: #6ef48c;
  }
  &.warning {
    background: rgba(236, 165, 59, 0.18);
    border: 1px solid #ffe600;
    color: #ffe600;
  }
  &.error {
    background: rgba(136, 32, 32, 0.47);
    border: 1px solid #da5151;
    color: #ffa3a3;
  }

  .head {
    @include flex(row, center, flex-start, nowrap);

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      &.error {
        width: 47px;
        height: 25px;
        margin-right: 30px;
      }
      img {
        display: block;
        width: 100%;
      }
    }
    span {
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
      color: inherit;
    }
    .audited_by {
      margin-left: auto;
    }
  }

  .content {
    margin-top: 15px;
    &.error {
      margin-top: 5px;
    }
    p {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
