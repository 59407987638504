@import '/src/styles/mixins';
@import '/src/styles/variables';

.modal_wrapper {
  width: 100%;
  max-width: 409px;
  @include b(extramobile) {
    width: 335px;
  }
}
.validation_modal_wrapper {
  padding: 40px 25px;

  .title {
    margin-bottom: 30px;
    text-align: center;
    @include text-control(24px, 120%, 700, $text-primary-dark, $text-primary-light);
  }

  .text_wrapper {
    margin-bottom: 30px;
    :first-child {
      @include text-common(smd, 500);
    }
    :nth-child(2) {
      @include text-common(smd);
      color: $text-label-dark;
    }
  }
}
