@import '../../styles/variables';
@import '../../styles/mixins';

.footer_wrapper {
  width: 100%;
  padding-left: 84px;
  margin-top: auto;
  @include dark {
    background-color: $bg-footer-dark;
  }
  @include light {
    background-color: $bg-footer-light;
  }
  &.sidebar {
    padding-left: 246px;
  }
  @include b(tablet) {
    padding: 0;
    &.sidebar {
      padding-left: 0;
    }
  }
  @include b(extramobile) {
    padding: 44px 20px !important;
  }
}

.container {
  padding: 30px 42px;
  white-space: nowrap;
  overflow: hidden;
  @include flex(row, flex-start, flex-start, nowrap);
  @include b(tablet) {
    @include flex(column);
    padding: 71px 0 72px;
    max-width: 485px;
    margin: 0 auto;
  }
  @include b(extramobile) {
    padding: 0;
    max-width: 100%;
  }

  .title {
    font-size: 14px;
    line-height: 21px;
    color: $text-footer-title;
  }
  .subtitle {
    font-size: 11px;
    color: $text-footer-title;
    letter-spacing: 0.03em;
    line-height: 16px;
    margin-bottom: 3px;
  }
  .description {
    font-size: 14px;
    line-height: 21px;
    @include dark {
      color: $text-footer-description-dark;
    }
    @include light {
      color: $text-footer-description-light;
    }
  }

  .rcq_wrapper {
    padding: 20px;
    border-radius: 15px;
    @include flex(row, normal, space-between);
    width: 100%;
    max-width: 445px;
    margin-right: 60px;
    min-height: 150px;
    @include b(tablet) {
      margin-right: 0;
      margin-bottom: 30px;
      max-width: 100%;
    }
    @include dark {
      background-color: $bg-main-dark;
    }
    @include light {
      background-color: $bg-main-light;
    }

    .rcq_col {
      @include flex(column, normal, space-between);
      &.summary {
        flex: 1;
        margin-left: 30px;
        @include b(tablet) {
          margin-left: 70px;
        }
        @include b(extramobile) {
          margin-left: 0;
          margin-bottom: 0;
        }
      }
      &.quack {
        @include b(extramobile) {
          @include flex(row, center, space-between);
        }
      }
      @include b(extramobile) {
        width: 100%;
        margin-bottom: 37px;
        @include flex(row, center, space-between);
      }
    }
    .rcq_logo,
    .rcq_actions {
      @include flex(row);
    }
    .wallet_btn {
      margin-left: 10px;
      font-size: 10px;
      line-height: 15px;
      font-weight: 600;
      padding: 6px 15px;
    }
    .wallet_icon {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: url('../../assets/img/icons/wallets/metamask.svg') center no-repeat;
      background-size: 24px;
      @include dark {
        background-color: #1b1c50;
      }
      @include light {
        background-color: #f4f5f8;
      }
    }
    .rcq_amount {
      font-size: 14px;
      letter-spacing: 0.03em;
      line-height: 16px;
      @include dark {
        color: $text-footer-description-dark;
      }
      @include light {
        color: $text-footer-description-light;
      }
    }
    .rcq_summary_item {
      @include flex(row, normal, space-between);
      @include b(extramobile) {
        width: 100%;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu_wrapper {
    @include grid(repeat(3, minmax(0, 1fr)), 1fr, 40px);
    @include b(mobile) {
      @include flex(row, flex-start, space-between, wrap);
    }
    .menu_item {
      @include flex(column, flex-start);
      @include b(mobile) {
        max-width: 125px;
        width: 100%;
      }
    }

    .menu_link {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 9px;
      text-decoration: none;
      letter-spacing: 0.03em;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:hover {
        text-decoration: underline;
      }
      @include dark {
        color: $text-primary-dark;
      }
      @include light {
        color: $text-primary-light;
      }
    }
  }
}
