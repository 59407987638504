@import 'styles/mixins';
@import 'styles/variables';

.tok_form {
  &__type {
    @include flex(row, center, flex-start, nowrap);
    @include box(dark, 12px);
    width: fit-content;
    padding: 12px;
    margin: 15px 0 25px;
    @include b('mobile') {
      margin: 20px auto;
      width: 100%;
      @include flex(row, center, space-between, nowrap);
    }
    &__item {
      padding: 5px 12px;
      color: $text-input-placeholder-dark;
      cursor: pointer;
      @include text-common(smd);
      @include b('mobile') {
        @include text-common(sm);
      }
      & + & {
        margin-left: 14px;
      }
      &_active {
        @include box(blue, 6px);
        color: white;
        @include text-common(smd, 600);
        @include b('mobile') {
          @include text-common(sm, 600);
        }
      }
    }
  }
  &__btns {
    margin-top: 78px !important;
    justify-content: flex-end !important;
    .cancel_btn {
      padding: 9px 29px;
    }
  }
}
