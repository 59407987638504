@import 'styles/variables';
@import 'styles/mixins';

.tl_wrapper {
  width: 100%;
  padding: 40px 40px 50px;
  margin-top: 15px;
  @include box(blue);
  min-height: 625px;
}
.title {
  @include text-common(lmd, 500);
  margin-bottom: 30px;
  @include b(tablet) {
    @include text-common(smd, 500);
  }
}

.tab_content_wrapper {
  width: 100%;
  margin-top: 28px;
  height: calc(100% - 21px - 10px - 27px - 30px - 10px);
}

.locked_wrapper {
  margin-bottom: 30px;
  @include flex(column, center, center);

  .locked_item {
    @include flex(row, center, flex-start, nowrap);
    width: 100%;
    padding: 16px 17px;
    @include box(dark-blue-card, 10px);
    margin-bottom: 5px;

    .icon {
      width: 34px;
      height: 34px;
      margin-right: 17px;
    }
    .amount {
      @include text-common(smd, 500);
      margin-bottom: 1px;
    }
    .locked {
      @include text-control(
        9px,
        140%,
        normal,
        $text-project-description-dark,
        $text-project-description-dark
      );
      letter-spacing: 0.03em;
    }
    .type {
      color: $text-accent-dark;
      @include light {
        color: $text-accent-light;
      }
    }
    .col {
      margin-left: auto;
      text-align: right;
    }
    .dropdown {
      margin-left: 15px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #323464;
      @include flex(row, center, center);
      cursor: pointer;
    }
  }
}

.error_wrapper {
  margin-top: 30px;
  background: rgba(136, 32, 32, 0.47);
  border: 1px solid #da5151;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 15px 30px;
  @include flex(row, center);

  .error_icon {
    width: 30px;
    margin-right: 27px;
  }
  .content {
    width: calc(100% - 30px - 27px);
  }
  .description {
    @include text-control(12px, 140%, 500, #ffa3a3, #ffa3a3);
  }
  .progress_wrapper {
    position: relative;
    overflow: hidden;
    background: #361020;
    border-radius: 20px;
    width: 100%;
    height: 21px;
    margin-bottom: 10px;
    @include flex(row, center, center);
    span {
      z-index: 1;
      @include text-common(sm, 500);
      color: $text-primary-dark;
    }
    .filler {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      background: #e85858;
    }
  }
}

.empty_title {
  @include text-control(14px, 140%, 500, $text-accent-dark, $text-accent-light);
  text-align: center;
  margin-bottom: 5px;
  margin-top: 195px;
}
.empty_description {
  @include text-control(
    12px,
    140%,
    500,
    $text-project-description-dark,
    $text-project-description-dark
  );
  text-align: center;
}
