@import 'styles/mixins';
@import 'styles/variables';

.p_contr_item {
  &__pledge {
    @include text-common(smd);
    margin-top: 2px;
  }
  &__wrapper {
    @include flex(row, flex-start, flex-start, nowrap);
    margin-top: 20px;
    padding-bottom: 30px;
    @include b('mobile') {
      @include flex(column);
    }
  }
  &__box {
    max-width: 280px;
    width: 100%;
    &__btn {
      margin-top: auto;
      margin-bottom: 5px;
    }
    & + & {
      margin-left: 95px;
      @include b('mobile') {
        margin-left: 0;
        margin-top: 30px;
      }
    }
  }
  &__title {
    @include text-common(md);
    margin-bottom: 10px;
  }
  &__text {
    @include text-common(sm);
  }
  &__delivery {
    @include text-common(sm);
    margin: 3px 0;
  }
  &__more {
    margin-top: 20px;
    @include text-common(sm, 600);
    cursor: pointer;
    width: fit-content;
  }
  &__input {
    &__title {
      span {
        @include text-common(sm);
      }
      span + span {
        margin-left: 5px;
        color: $text-input-placeholder-dark;
      }
    }
  }
  &__btn {
    width: 100%;
  }
}
