@import 'styles/mixins';

.table {
  margin-top: 20px;
  &__head,
  &__item {
    grid-template-columns: 1fr 130px 120px 120px 130px 210px;
    grid-gap: 10px;
    align-items: center;
    @include b('tablet') {
      grid-template-columns: 1fr 110px 90px 210px;
    }
    @include b('mobile') {
      grid-template-columns: 1fr 80px 70px;
    }
  }
}
