@import '../../styles/variables';
@import '../../styles/mixins';

.discover_wrapper {
  width: 100%;
  @include b(tablet) {
    padding: 0;
  }

  .btn_wrapper {
    @include flex(row, center);
    @include b(tablet) {
      @include flex(row, center, flex-start, wrap);
    }
    @include b(extramobile) {
      @include flex(column, center, flex-start, wrap);
    }

    .btn_start {
      color: black;

      @include b(extramobile) {
        width: 100%;
      }
    }
    .btn_subscribe {
      margin-left: 15px;
      @include b(extramobile) {
        margin-left: 0;
        margin-top: 24px;
        width: 100%;
      }
    }
  }
}

.loader {
  margin-top: 64px;
  margin-bottom: 96px;
}
