@import 'styles/variables';
@import 'styles/mixins';

.block {
  @include box(blue);
  margin-top: 15px;
  padding: 17px 20px;
  @include flex(row, center, flex-start);
  font-weight: 500;

  @include light {
    background: $bg-select-light;
  }

  .blockImg {
    @include b('mobile') {
      max-width: 44px;
    }
  }

  @include b('mobile') {
    justify-content: flex-start;
  }

  .title {
    margin-left: 20px;
    color: white;

    @include light {
      color: $text-primary-light;
    }

    @include b('mobile') {
      margin-left: 15px;
    }

    @include b('340') {
      max-width: 180px;
    }
  }

  .smallBlock {
    margin-left: auto;
    background: $deepKoamaru;
    border-radius: 15px;
    padding: 12px 10px 12px 20px;
    width: 100%;
    max-width: 340px;
    @include flex(row, center, normal);

    @include light {
      background: $bg-main-light;
    }

    @include b('mobile') {
      max-width: none;
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      @include flex(row, center, space-between);
    }

    .collect {
      width: 100%;
      max-width: 150px;

      @include b('mobile') {
        max-width: 86px;
      }

      .collectText {
        @include font-asap;
        color: $text-label-dark;
        font-size: 10px;
        line-height: 11px;
      }
    }

    .harvestAllButton {
      max-width: 160px;
      height: 38px;
      padding: 0 38px;
    }
  }
}

.cardContainer {
  margin-left: auto;
  margin-top: 82px;
  @include flex(row, flex-start, center);

  @include b('mobile') {
    flex-direction: column;
  }
}
