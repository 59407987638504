@import '/src/styles/mixins';
@import '/src/styles/variables';

.l_harvest {
  margin-top: 30px;
  @include box(blue);
  padding: 17px 30px;
  overflow: hidden;
  @include b(extramobile) {
    margin-top: 70px;
  }

  .harvest_wrapper {
    @include flex(row, center, space-between, nowrap);
    @include b(extramobile) {
      @include flex(column, flex-start);
    }
    .user_info {
      @include text-common(smd, 600);
      letter-spacing: 0.05em;
      @include flex(row, center, flex-start);
      .user_img {
        width: 58px;
        margin-right: 20px;
        img {
          width: 100%;
        }
      }
    }
    .harvest_container {
      background: #32345C;
      @include light {
        background: #f9f9f9;
      }
      border-radius: 15px;
      padding: 12px 10px 12px 20px;
      @include flex(row, center, flex-start, nowrap);
      margin-left: auto;
      @include b(extramobile) {
        width: 100%;
        margin-top: 20px;
      }

      .harvest_amount {
        .amount {
          @include text-control(18px, 27px, 600, $text-accent-dark, $text-accent-light);
        }
        .label {
          @include font-asap;
          @include text-control(10px, 11px, 500, $text-label-dark, $text-label-light);
          @include b(extramobile) {
            @include text-control(13px, 15px, 500, $text-label-dark, $text-label-light);
          }
        }
        .token {
          @include font-asap;
          @include text-control(10px, 11px, 500);
          @include b(extramobile) {
            @include text-control(13px, 15px, 500);
          }
        }
      }

      .harvest_btn {
        margin-left: 86px;
        padding: 9px 38px;
        @include b(mobile) {
          margin-left: 43px;
        }
        @include b(extramobile) {
          margin-left: auto;
        }
      }
    }
  }
}
