@import '../../styles/mixins';

$bg-button-dark: #ffbb0b;
$bg-button-light: #ff9900;
$bg-button-disabled-dark: #464771;
$bg-button-disabled-light: #e9e9ee;
$bg-button-green-active: #2c682e;
$bg-button-red-active: #742721;

$bg-button-hover-outline-dark: #33302b;
$bg-button-hover-filled-dark: #ffd772;
$bg-button-hover-outline-light: #fffaee;
$bg-button-hover-filled-light: #ffc700;

$bg-button-focus-filled-dark: #ff800b;
$bg-button-focus-outline-dark: #ffb803;
$bg-button-focus-filled-light: #f3a761;
$bg-button-focus-outline-light: #fff2d0;

$text-button-dark: #ffbb0b;
$text-button-filled: #11333e;
$text-button-focus-outline: #ffbb0b;
$text-button-green-text: #11333e;
$text-button-filled-disabled: #8889a5;
$text-button-outline-disabled-dark: #696a90;
$text-button-outline-disabled-light: #b5b5c8;

$border-button-disabled-outline-dark: 1px solid #2d2e57;
$border-button-disabled-outline-light: 1px solid #e9e9ee;

$shadow-button-hover: 0 0 44px rgba(255, 187, 11, 0.65);

$green: #53da58;
$red: #ff5649;

.button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease, border-color 0.4s ease;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid transparent;
  color: $text-button-dark;
  width: fit-content;
  white-space: nowrap;
  @include flex(row, center, center, nowrap);
  @include dark {
    border: 1px solid $bg-button-dark;
  }
  @include light {
    border: 1px solid $bg-button-light;
  }

  &.default {
    font-size: 13px;
    line-height: 19.5px;
    padding: 12px 65px;
    border-radius: 54px;
  }
  &.medium {
    font-size: 12px;
    line-height: 18px;
    padding: 10px 25px;
    border-radius: 54px;
  }
  &.small {
    font-size: 10px;
    line-height: 15px;
    padding: 6px 15px;
    border-radius: 32.6842px;
  }

  &.filled {
    color: $text-button-filled;
    background-color: $bg-button-dark;
    @include light {
      background-color: $bg-button-light;
    }
  }

  &:hover {
    background: $bg-button-hover-outline-dark;
    @include light {
      backdrop-filter: blur(7px);
      background: $bg-button-hover-outline-light;
    }
    &.filled {
      background: $bg-button-hover-filled-dark;
      @include dark {
        box-shadow: $shadow-button-hover;
      }
      @include light {
        border: 1px solid transparent;
        background: $bg-button-hover-filled-light;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: $text-button-outline-disabled-dark;
    border: $border-button-disabled-outline-dark;
    backdrop-filter: blur(7px);
    @include light {
      color: $text-button-outline-disabled-light;
      border: $border-button-disabled-outline-light;
    }
    &.filled {
      background: $bg-button-disabled-dark;
      color: $text-button-filled-disabled;
      @include light {
        background: $bg-button-disabled-light;
        color: $text-button-filled-disabled;
      }
    }
  }
  &:focus {
    background: $bg-button-focus-outline-dark;
    color: $text-button-filled;
    @include dark {
      box-shadow: $shadow-button-hover;
    }
    @include light {
      border: none;
      color: $text-button-focus-outline;
      background: $bg-button-focus-outline-light;
      backdrop-filter: blur(7px);
    }
    &.filled {
      background: $bg-button-focus-filled-dark;
      border: 1px solid transparent;
      @include light {
        color: $text-button-filled;
        background: $bg-button-focus-filled-light;
      }
    }
  }

  &.isFullWidth {
    width: 100%;
  }

  &.gray {
    @extend .button.disabled;
    cursor: pointer;
  }
  &.green {
    background: $green;
    border-color: $green;
    color: $text-button-green-text;
    font-weight: 500;
    &:hover {
      background: $bg-button-green-active;
      border-color: $bg-button-green-active;
    }
    path {
      fill: $text-button-green-text;
    }
    &:focus {
      box-shadow: none;
    }
    &.active,
    &:active {
      font-weight: 500;
      color: $green;
      background: $bg-button-green-active;
      border-color: $bg-button-green-active;
      path {
        fill: $green;
      }
    }
  }
  &.red {
    background: $red;
    border-color: $red;
    color: $text-button-green-text;
    font-weight: 500;
    &:hover {
      background: $bg-button-red-active;
      border-color: $bg-button-red-active;
    }
    path {
      fill: $text-button-green-text;
    }
    &:focus {
      box-shadow: none;
    }
    &.active,
    &:active {
      font-weight: 500;
      color: $red;
      background: $bg-button-red-active;
      border-color: $bg-button-red-active;
      path {
        fill: $red;
      }
    }
  }
}

.icon {
  height: unset;
}
