* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-size: 14px;

  span,
  div {
    font-feature-settings: 'liga' off;
  }

  .light {
    color: #244b57;
  }

  .dark {
    color: #ffffff;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.dark {
  transition: all 0.1s;

  &.main_wrapper {
    background-color: $bg-main-dark;
  }
}

.light {
  transition: all 0.1s;

  &.main_wrapper {
    background-color: $bg-main-light;
  }
}

.main_wrapper {
  position: relative;
  z-index: 0;
  min-height: 100vh;
}

.page_wrapper {
  @include flex(column, normal, space-between);
  overflow: hidden;

  z-index: 0;
  padding-top: 60px;
  padding-left: 84px;
  min-height: calc(100vh - 220px - 80px);

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-blend-mode: unset;
  transition: all 0.3s ease 0s;
  transform: translateX(0);
  @include b('tablet') {
    min-height: calc(100vh - 600px);
  }

  &.active {
    padding-left: 246px;
    transform: translateX(0);
    transition: all 0.3s ease 0s;
    @include b(tablet) {
      padding: 0 140px;
    }
    @include b(mobile) {
      padding: 0 20px;
    }
  }
  @include b(tablet) {
    padding: 0 140px;
  }
  @include b(mobile) {
    padding: 0 20px;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: calc(1110px + 42px * 2);
  padding: 0 42px;
  @include b('tablet') {
    padding: 0;
  }
  @include b(tablet) {
    padding: 10px 0 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.hidden {
  &__tablet {
    @include b('tablet') {
      display: none;
    }
  }
  &__mobile {
    @include b('mobile') {
      display: none;
    }
  }
}

.projects_wrapper {
  width: 100%;
  margin-bottom: 18px;
  @include grid(repeat(4, minmax(0, 1fr)), 1fr, 30px);
  @include b(tablet) {
    @include grid(repeat(3, minmax(0, 1fr)), 1fr, 30px);
  }
  @include b(mobile) {
    @include grid(repeat(2, minmax(0, 1fr)), 1fr, 30px);
  }
  @include b(extramobile) {
    @include grid(repeat(1, minmax(0, 1fr)), 1fr, 30px);
  }
}
.ScrollbarsCustom {
  &-ThumbY {
    background: #ffb800 !important;
  }
  &-TrackY {
    width: 2px !important;
    z-index: 1;
  }
  &-Scroller {
    // padding-right: 10px !important;
  }
  &-Wrapper {
    inset: 0 0 0 0 !important;
    z-index: 0;
  }
}
iframe {
  display: none !important;
}
