@import '../../styles/variables';
@import '../../styles/mixins';

$status-voting: #FFBB0B;
$status-upcoming: #e2bcff;
$status-register: #F6851B;
$status-open: #38FFE7;
$status-register-closed: #FDB8B5;
$status-completed: #53DA58;

.status {
  @include flex(row, center, space-between, nowrap);
  border-radius: 40px;
  padding: 3px 10px 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  @include dark {
    background: $bg-project-status-dark;
  }
  @include light {
    background-color: $bg-box-dark-blue-light;
  }

  .indicator {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &.voting {
    color: $status-voting;
    .indicator {
      background-color: $status-voting;
    }
  }
  &.upcoming {
    color: $status-upcoming;
    .indicator {
      background-color: $status-upcoming;
    }
  }
  &.register {
    color: $status-register;
    .indicator {
      background-color: $status-register;
    }
  }
  &.registration-closed {
    color: $status-register-closed;
    .indicator {
      background-color: $status-register-closed;
    }
  }
  &.open {
    color: $status-open;
    .indicator {
      background-color: $status-open;
    }
  }
  &.completed {
    color: $status-completed;
    .indicator {
      background-color: $status-completed;
    }
  }

  &.public, &.private, &.inqubator {

    .indicator {
      display: none;
    }
  }
}
