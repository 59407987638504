@import 'styles/variables';
@import 'styles/mixins';

.card {
  @include box(blue);
  width: 100%;
  max-width: 255px;
  border: 0;
  overflow: hidden;
  position: relative;

  @include light {
    background-color: $bg-main-light;
    box-shadow: 0 7px 27px rgba(58, 104, 124, 0.09);
  }

  @include b('tablet') {
    max-width: 228px;
    margin-top: 20px;
  }

  @include b('mobile') {
    max-width: 335px;
    margin: 20px auto auto;
  }

  //svg {
  //  fill: red;
  //}

  .circleImg,
  .backgroundImg,
  .badge {
    img {
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .circleImg {
    position: absolute;
    width: 75px;
    height: 75px;
    margin-top: 83px;
    margin-left: 15px;
  }

  .backgroundImg {
    width: 100%;
    height: 119px;
    border-radius: 15px 15px 0 0;
  }

  .badge {
    position: absolute;
    right: 15px;
  }

  .content {
    padding: 47px 15px 20px 15px;

    .title {
      letter-spacing: 0.05em;
      text-transform: uppercase;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @include text-common(lmd, 600);
      margin-bottom: 1px;
    }
    .quaqLabel {
      @include font-asap;
      line-height: 160%;
    }

    .quackValue {
      margin-top: 1px;
    }

    .table {
      @include flex(row, center, flex-start, nowrap);
      margin-top: 15px;

      .item {
        width: 50%;

        .colName {
          @include font-asap;
        }

        .colValue {
          margin-top: 2px;
        }
      }
    }

    .text_container {
      margin-top: 15px;
    }
    .label {
      @include font-asap;
    }

    .yellowValue {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
    }

    .value {
      @include font-asap;
      font-size: 12px;
      line-height: 20px;
    }
    .button {
      width: 100%;
      margin-top: 25px;
    }
    .btns {
      @include flex(row, center, space-between, nowrap);
      .button + .button {
        margin-left: 5px;
      }
    }

    .actionBar {
      @include flex;
      margin-top: 30px;

      @include b('tablet') {
        margin-top: 40px;
      }

      .unstakePool {
        width: 100%;
        max-width: 120px;
        padding: 0 16px;
        height: 38px;

        @include b('tablet') {
          max-width: 110px;
          margin-right: 2px;
          padding: 0 10px;
        }

        @include b('mobile') {
          max-width: 120px;
          margin-right: 10px;
        }

        .name {
          text-transform: uppercase;
        }
      }

      .buttonCircle {
        border-radius: 20px;
        height: 40px;
        width: 100%;
        max-width: 40px;
        padding: 0;
        margin-left: 10px;

        .minus {
          width: 14px;
          border: 1px solid $bg-main-light;
          border-radius: 1px;
          margin: auto;

          @include light {
            border-color: $bg-accent;
          }
        }

        @include b('tablet') {
          margin-left: 3px;
        }

        @include b('mobile') {
          margin-left: 10px;
        }
      }
    }

    .details_dropdown {
      cursor: pointer;
      margin-top: 9px;
      @include flex(row, center, center);
      span {
        margin-right: 5px;
        @include text-common(smd, 600);
        color: $text-accent-dark;
        @include light {
          color: $text-accent-light;
        }
      }
    }
    .details {
      padding-top: 10px;

      .row {
        @include flex(row, center, space-between);
        margin-bottom: 6px;

        &_value {
          @include text-common(sm, 600);
        }
        &_action {
          cursor: pointer;
          text-align: center;
          @include text-common(smd, 600);
          color: $text-accent-dark;
          @include light {
            color: $text-accent-light;
          }
        }
      }
    }
  }
}

.card:not(:last-child) {
  margin-right: 30px;

  @include b('mobile') {
    margin-right: auto;
  }
}

:global(.details-show) {
  &-enter {
    max-height: 0;
    opacity: 0;
  }
  &-enter-active {
    padding-top: 10px;
    max-height: 2000px;
    opacity: 1;
    transition: padding 300ms, max-height 300ms, opacity 500ms;
  }
  &-exit {
    padding-top: 10px;
    max-height: 2000px;
    opacity: 1;
  }
  &-exit-active {
    padding-top: 10px;
    opacity: 0;
    max-height: 0;
    transition: padding 300ms, max-height 180ms, opacity 300ms;
  }
}
