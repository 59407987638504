@import 'styles/mixins/';
@import 'styles/variables';

.slider_wrapper {
  width: calc(100% + 40px);
  margin: 0 -20px;
}
.l_slider {
  margin-top: 100px;
  max-width: 270px;
  overflow: visible;
  @include b(mobile) {
    //margin: 70px -20px 0;
  }
  &__slide {
    transition-property: transform, opacity;
    opacity: 0;
  }
  &__card {
    background: radial-gradient(74.78% 100% at 50.17% 0%, #c99632 0%, #682380 100%);
    border-radius: 15px;
    min-height: 494px;
    padding: 30px 30px 35px;
    text-align: center;
    color: white;
    @include flex(column, center, flex-start, nowrap);
    @include light {
      background: radial-gradient(74.78% 100% at 50.17% 0%, #c99632 0%, #682380 100%);
    }
    &_lottery {
      background: radial-gradient(74.78% 100% at 50.17% 0%, #a15cb9 0%, #682380 100%);
      @include light {
        background: radial-gradient(74.78% 100% at 50.17% 0%, #a15cb9 0%, #682380 100%);
      }
    }
    &__title {
      @include text-common(slg, 600);
    }
    &__tag {
      padding: 6px 16px;
      @include text-common(smd, bold);
      background: #5a3ad8;
      width: fit-content;
      border-radius: 25px;
      margin: 10px 0 30px;
      &_lottery {
        background: #ff8328;
      }
    }
    &__numb {
      font-weight: 500;
      font-size: 96px;
      line-height: 144px;
    }
    &__name {
      @include text-common(lmd, 600);
      margin: 10px 0;
      text-transform: uppercase;
    }
    &__value {
      @include text-common(xxl, bold);
    }
    &__curr {
      @include text-common(lmd);
      color: #D6D6D6;
    }
    &__info {
      margin-top: 10px;
      @include text-common(md);
    }
  }

  :global(.swiper) {
    &-slide {
      &-active {
        opacity: 1;
      }
      &-prev,
      &-next {
        opacity: 0.5;
      }
    }
    &-button {
      &-prev {
        left: -250px;
        height: 48px;
        width: 48px;
        &::after {
          content: '';
          height: 48px;
          width: 48px;
          display: block;
          background-image: url(../../../assets/img/icons/arrow-slider-prev.svg);
          @include light {
            background-image: url(../../../assets/img/icons/arrow-slider-prev-light.svg);
          }
        }
      }
      &-next {
        right: -250px;
        height: 48px;
        width: 48px;
        &::after {
          transform: rotate(180deg);
          content: '';
          height: 48px;
          width: 48px;
          display: block;
          background-image: url(../../../assets/img/icons/arrow-slider-prev.svg);
          @include light {
            background-image: url(../../../assets/img/icons/arrow-slider-prev-light.svg);
          }
        }
      }
    }
  }
}
