@import 'styles/variables';
@import 'styles/mixins';

.container {
  @include flex(row, normal, normal, nowrap);

  @include b('mobile') {
    @include flex(column, normal, normal, nowrap);
  }

  .textInfo {
    margin-left: 30px;
    @include b('mobile') {
      margin-left: 0;
    }

    h1 {
      @include b('mobile') {
        text-align: center;
      }
    }

    .description {
      font-weight: 500;
      line-height: 140%;
      margin-top: 10px;
      color: $text-label-dark;
      margin-right: 30px;

      @include b('mobile') {
        margin-right: 0;
        text-align: center;
      }
    }
  }

  .blockLaunchpad {
    border-radius: 15px;
    padding: 20px;

    @include dark {
      background: $bg-project-card-dark;
    }
    @include light {
      box-shadow: 0 7px 27px rgba(58, 104, 124, 0.09);
    }

    @include b('mobile') {
      margin-top: 20px;
    }

    .blockTitle {
      @include flex(row, normal, space-between, nowrap);
      white-space: nowrap;
      letter-spacing: 0.05em;

      .info_icon {
        margin-left: 12px;
        width: 22px;
        height: 22px;
        @include flex(row, center, center);
        background-color: $deepKoamaru;
        border-radius: 50%;
        cursor: pointer;
        &:hover {
          path {
            fill: black;
          }
          background-color: $bg-accent-dark;
          @include light {
            background-color: $bg-accent-light;
          }
        }
      }
    }

    .table {
      @include flex(row);
      margin-top: 20px;
      max-width: 300px;

      .table__item {
        width: 100%;
        max-width: 90px;

        .colName {
          @include font-asap;
        }

        .colValue {
          color: $text-accent-dark;
          margin-top: 2px;
        }
      }
    }
  }
}
