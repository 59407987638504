@import 'styles/mixins';
@import 'styles/variables';

.sw__modal {
  max-width: 465px;
  padding: 30px 30px 40px;
  @include b('mobile') {
    padding: 20px;
  }
  &__content {
    text-align: center;
    @include text-common(smd);
  }
  &__title {
    @include text-control();
    @include text-common(slg, bold);
    margin: 15px 0 5px;
  }
  &__text {
    & + & {
      margin-top: 20px;
    }
  }
  &__check {
    margin: 20px 0 30px;
    @include b('mobile') {
      margin: 20px 0;
    }
    &__text {
      margin-left: 15px;
    }
  }
  &__btns {
    @include flex(row, center, space-between, nowrap);
    @include b('mobile') {
      @include flex(column-reverse, center, space-between, nowrap);
    }
    &__item {
      width: 100%;
      @include b('mobile') {
        margin-top: 10px;
      }
      & + & {
        margin-left: 10px;
        @include b('mobile') {
          margin-left: 0;
        }
      }
    }
  }
  a {
    @include b('mobile') {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
      display: block;
    }
  }
}
