@import '../../styles/variables';
@import '../../styles/mixins';

.page_preview {
  width: 100%;
  border-radius: 20px;
  padding: 40px 0 42px 40px;
  margin-bottom: 19px;
  position: relative;
  &.reverse {
    padding: 58px 50px;
    @include b('tablet') {
      padding: 40px 30px;
    }
    @include b('mobile') {
      padding: 285px 20px 40px;
      text-align: center;
    }
  }
  &.dark {
    @include dark {
      background: $bg-gradient-dark;
    }
    @include light {
      background: $bg-gradient-light;
    }
  }
  &.light {
    @include dark {
      background: radial-gradient(
        92.12% 699.07% at 6.99% 22.64%,
        #181a64 0%,
        rgba(24, 26, 100, 0.54) 100%
      );
    }
    @include light {
      background: $bg-gradient-light;
    }
  }
  @include b('mobile') {
    padding: 30px 30px 35px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 120%;
    font-weight: 500;
    margin-bottom: 10px;
    @include dark {
      color: $text-color-subtitle-dark;
    }
    @include light {
      color: $text-color-subtitle-light;
    }
    @include b('mobile') {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .title {
    font-size: 28px;
    line-height: 120%;
    font-weight: 700;
    @include b('mobile') {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .btns {
    margin-top: 20px;
  }
  .mega_title {
    font-size: 64px;
    line-height: 77px;
    font-weight: 600;
    margin-bottom: 5px;
    @include b('mobile') {
      font-size: 48px;
      line-height: 58px;
      margin: 10px 0;
    }
  }
  &.reverse &__wrapper {
    @include flex(column-reverse);
  }
  &.reverse .subtitle {
    @include text-common(md);
  }
  .img {
    position: absolute;
    right: 25px;
    top: -62px;
    @include b('tablet') {
      max-width: 375px;
      top: -17px;
      right: 6px;
    }
    @include b('mobile') {
      max-width: 240px;
      top: 28px;
      right: 50%;
      transform: translate3d(50%, 0, 0);
    }
  }
}
