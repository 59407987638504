@import 'styles/mixins';
@import 'styles/variables';

$item: '.l_steps__item';

.l_steps {
  overflow: hidden;
  margin-top: 40px;
  @include box(blue);
  padding: 44px 10px 50px 40px;
  @include flex(row, flex-start, flex-start, nowrap);
  @include b(mobile) {
    padding: 30px 20px;
  }

  :global(.swiper-slide) {
    width: 210px !important;
    &:first-of-type {
      width: 125px !important;;
      min-width: 125px;
    }
    &:nth-of-type(2) {
      .l_steps__item {
        &__circle {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  :global(.swiper-slide-next) {
    opacity: 1;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &__items {
    overflow: visible;
    width: 100%;
  }
  &__item {
    width: 210px;
    min-width: 210px;
    &__circle {
      width: 14px;
      height: 14px;
      background: #818294;
      border-radius: 50%;
      margin-bottom: 10px;
      position: relative;
      z-index: 2;
      @include light {
        background: #cacaca;
      }
    }
    &__name {
      @include text-control();
      @include text-common(md, bold);
    }
    &__value {
      @include text-common(lmd, bold);
    }
    &__descr {
      @include text-common(sm, 500);
    }
    &__circle::before {
      content: '';
      width: calc(210px - 14px);
      position: absolute;
      display: block;
      z-index: 1;
      height: 3px;
      background: #2c3741;
      top: 50%;
      right: 100%;
      transform: translate3d(0, -50%, 0);
      @include light {
        background: #e9e9e9;
      }
    }
    &_active {
      #{$item} {
        &__circle {
          background: $bg-accent-light;
          &::before {
            background: $bg-accent-dark !important;
          }
        }
      }
    }
  }
  &__wrapper {
    min-width: 125px;
    margin-top: 1px;
  }
  &__balance {
    padding: 3px 15px;
    background: #585974;
    border-radius: 10px;
    @include text-common(smd, 500);
    margin-top: -7px;
    width: fit-content;
    max-width: 80%;
    position: relative;
    @include light {
      background: #e9e9e9;
    }
    &::after {
      content: '';
      width: 80px;
      position: absolute;
      display: block;
      z-index: 1;
      height: 3px;
      background: #2c3741;
      top: 50%;
      left: 100%;
      transform: translate3d(0, -50%, 0);
      @include light {
        background: #e9e9e9;
      }
    }
    &_active {
      background: $bg-accent-dark;
      &::after {
        background: $bg-accent-dark;
      }
      @include light {
        background: $bg-accent-light;
        &::after {
          background: $bg-accent-light;
        }
      }
    }
  }

  :global(.swiper) {
    &-slide {
      &-active {
        opacity: 1;
      }
      &-next, &-prev {
        opacity: 1;
      }
    }
    &-button {
      &-prev {
        left: -60px;
        top: -9px;
        height: 48px;
        width: 48px;
        @include b(mobile) {
          left: -41px;
        }
        &::after {
          content: '';
          transform: rotate(180deg);
          height: 48px;
          width: 48px;
          display: block;
          background-image: url(../../../assets/img/icons/arrow-steps.svg);
          background-repeat: no-repeat;
          @include light {
            background-image: url(../../../assets/img/icons/arrow-steps-light.svg);
          }
        }
      }
      &-next {
        right: -30px;
        top: 19px;
        height: 48px;
        width: 48px;
        @include b(mobile) {
          right: -41px;
        }
        &::after {
          content: '';
          height: 48px;
          width: 48px;
          display: block;
          background-image: url(../../../assets/img/icons/arrow-steps.svg);
          background-repeat: no-repeat;
          @include light {
            background-image: url(../../../assets/img/icons/arrow-steps-light.svg);
          }
        }
      }
      &-disabled {
        opacity: 0;
      }
    }
  }
}
