@import 'styles/mixins';
@import 'styles/variables';

.km_form {
  &__prefix {
    margin-right: 20px;
  }
  &__postfix {
    margin-left: 20px;
    margin-right: -15px;
  }
  &__container {
    & + & {
      margin-top: 65px;
    }
  }
  &__title {
    @include text-common(lmd, bold);
    span {
      font-weight: 500;
    }
  }
  &__row {
    margin-top: 20px !important;
  }
  &__btns {
    margin-top: 57px !important;
  }
}
