@import '../../styles/variables';
@import '../../styles/mixins';

.selector {
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  position: relative;
  letter-spacing: 0.02em;
  @include flex(row, center, space-between, nowrap);

  @include dark {
    background: $bg-select-dark;
  }
  @include light {
    background: $bg-select-light;
  }
  &.gray {
    @include light {
      background: $bg-select-gray;
    }
  }
  &.dark,
  &.dark .option_wrapper {
    background: $bg-deep-dark;
    @include light {
      background: $bg-box-dark-blue-light;
    }
  }
  &.gray .option_wrapper {
    @include light {
      background: $bg-select-gray;
    }
  }
  &.mini {
    @include text-common(sm);
    border-radius: 22px;
  }
  &.big {
    @include text-common(smd);
    border-radius: 15px;
    border: 2px solid transparent;
  }
  &.currency {
    font-weight: 500;
  }
  &.active {
    background: $bg-select-active-dark;
    box-shadow: $bg-select-shadow;
    @include light {
      background: $bg-select-active-light;
      box-shadow: none;
    }
  }
}

.currentOption {
  position: relative;
  @include flex(row, center, space-between, nowrap);
  width: 100%;
  &__wrapper {
    @include flex(row, center);
  }
  &.mini {
    padding: 9px 15px;
  }
  &.big {
    padding: 13px 20px;
  }
}

.arrow {
  transition: transform 0.2s ease-in-out;
  @include light {
    filter: $svg-filter-dropdown;
  }
  &.active {
    transform: rotate(180deg);
  }
}

.option_wrapper {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  overflow: hidden;
  width: 100%;
  margin-top: 2px;
  border-radius: 10px;
  z-index: 999;
  @include dark {
    background: $bg-select-dark;
  }
  @include light {
    background: $bg-main-light;
    box-shadow: $project-card-box-shadow;
  }
  &__scroll {
  }
}

.option {
  left: 0;
  @include text-common(sm);
  padding: 5px 10px;
  position: relative;
  z-index: 999;
  & + & {
    margin-top: 10px;
  }
  @include flex(row, center);
  @include dark {
    color: $text-primary-dark;
  }
  @include light {
    color: $text-primary-light;
  }
  &_active {
    background: #0f1030;
    @include light {
      background: #f0f0f0;
    }
  }
}

.img {
  margin-right: 15px;
  height: fit-content;
  @include flex(row, center);
  &_currency {
    //width: 42px;
    img {
      min-width: 25px;
    }
  }
}
:global(.ScrollbarsCustom-Content) {
  padding: 15px 0 !important;
}

.title {
  margin-bottom: 5px;
  padding-left: 10px;
}
.subtitle {
  margin-top: 5px;
  padding-left: 10px;
}
.error {
  padding-left: 10px;
  @include text-control(12px, 14px, normal, $red, $red);
}
