@import 'styles/mixins';
@import 'styles/variables';

.tlr_form {
  &__row {
    margin-top: 20px !important;
  }
  &__btns {
    margin-top: 89px !important;
  }
}
