@import 'styles/variables';
@import 'styles/mixins/index';

.modal_wrapper {
  width: 100%;
  max-width: 535px;
  @include b(extramobile) {
    width: 335px;
  }
}
.subscribe_modal {
  padding: 40px 30px;
  .title {
    @include text-control(24px, 120%, 700, $text-primary-dark, $text-primary-light);
    margin-bottom: 10px;
  }
  .subtitle {
    @include text-control(14px, 140%, 500, $text-tier-info, $text-tier-info);
    margin-bottom: 26px;
  }
  .label {
    @include text-control(12px, 120%, 600, $text-primary-dark, $text-primary-light);
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .input {
    margin-bottom: 37px;
    div {
      border: none;
    }
    input {
      @include box(dark);
    }
  }
  .btn_wrapper {
    @include flex(row, center, flex-end);

    .submit_btn {
      padding: 10px 36px;
    }
    .cancel_btn {
      padding: 10px 28px;
      margin-right: 15px;
    }
  }
}
