@import 'styles/variables';
@import 'styles/mixins';

.presale_wrapper {
  margin-top: 27px;
}
.tab_head {
  @include flex(row, flex-start, space-between, nowrap);
  margin-bottom: 46px;
}
.title {
  @include text-common(lmd, 500);
  margin-bottom: 3px;
  @include b(tablet) {
    @include text-common(smd, 500);
    padding: 0;
  }
}
.subtitle {
  @include text-control(12px, 18px, normal);
  @include description;
  @include flex(row, center);
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: $dot-color;
    margin: 0 15px;
  }
}

.status {
  padding: 3px 11px;
  background: #3b41b5;
  border-radius: 14px;
  @include text-control(12px, 18px, normal, $text-primary-dark, $text-primary-dark);
}
.progress_wrapper {
  @include flex(row, center, center, nowrap);
  margin-bottom: 30px;

  .progress_item {
    position: relative;
    @include flex(column, center);
    &:nth-of-type(2) {
      margin: 0 43px;
    }

    .progress {
      width: 70px;
      @include b(tablet) {
        width: 66px;
      }
    }

    .icon {
      position: absolute;
      top: 18px;
      @include light {
        path {
          fill: $bg-accent-light;
        }
      }
    }
    .percent {
      @include text-common(lmd, 700);
      margin-top: 8px;
      margin-bottom: 3px;
      color: $text-accent-dark;
      @include light {
        color: $text-accent-light;
      }
    }
  }
}

.requirements {
  @include box(dark-blue-card, 10px);
  padding: 26px 30px 30px;
  margin-bottom: 28px;

  .description {
    margin: 5px 0 0;
    @include text-control(
      12px,
      140%,
      normal,
      $text-project-description-dark,
      $text-project-description-dark
    );
    letter-spacing: 0.03em;
  }

  .req_btn {
    margin: 30px 20px 0;
    width: calc(100% - 40px);
    @include b(mobile) {
      margin: 0;
      width: 100%;
    }
  }

  .moon_round_wrapper {
    @include flex(row, flex-start, flex-start, nowrap);
    margin-top: 16px;
    padding: 20px 35px 20px 20px;
    background: $bg-requirements-box-dark;
    border-radius: 10px;

    .logo {
      width: 32px;
      height: 32px;
      margin-top: 4px;
      margin-right: 15px;
    }

    .info {
      width: 100%;
      .row {
        width: 100%;
        @include flex(row, center, space-between, nowrap);
        margin-bottom: 15px;
        &:last-of-type {
          padding: 0;
          margin-top: 5px;
        }
      }
      .item {
      }
      .label {
        @include text-common(sm);
        color: $text-project-description-dark;
        letter-spacing: 0.03em;
        &.value {
          color: $text-primary-dark;
          @include light {
            color: $text-primary-light;
          }
        }
        &.lg {
          @include text-common(smd, 500);
        }
      }
    }
  }
}
