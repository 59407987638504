@import '../../../styles/variables';
@import '../../../styles/mixins';

.map_wrapper {
  width: 100%;
  @include flex(column, center);
  position: relative;
  z-index: 0;
  margin-bottom: 108px;

  .circle {
    position: absolute;
    border: 4px solid #1e1f59;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    bottom: -58px;
    right: 207px;
    @include light {
      border: 4px solid #f3f3f3;
      right: 372px;
    }
  }
  .line {
    position: absolute;
    left: 0;
    bottom: -108px;
    @include light {
      left: -160px;
      svg {
        path {
          stroke: #eaeaea;
        }
      }
    }
  }

  .title {
    @include text-common(xl, 600);
    margin-bottom: 3px;
  }
  .subtitle {
    @include text-common(md);
    @include description;
    margin-bottom: 50px;
  }

  .quarter_container {
    position: relative;
    z-index: 10;
    width: 100%;
    @include flex(row, normal, space-between);

    .quarter {
      @include flex(column, center);
      padding: 40px 20px 10px;
      width: calc((100% - 60px) / 3);
      border-radius: 15px;
      @include dark {
        background-color: $bg-landing-box-dark;
      }
      @include light {
        background-color: $bg-main-light;
        box-shadow: $project-card-box-shadow;
      }

      .title {
        @include text-common(slg, 600);
        @include dark {
          color: $text-landing-dark;
        }
        @include light {
          color: $text-landing-light;
        }
        margin-bottom: 30px;
      }
      ul {
        li {
          @include flex(row, center, normal, nowrap);
          @include text-common(smd, 600);
          margin-bottom: 15px;
          list-style-position: inside;
          &::before {
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-right: 10px;
            align-self: flex-start;
          }
          &.checked {
            &::before {
              content: url('../../../assets/img/icons/landing/checked.svg');
            }
          }
          &.in_progress {
            &::before {
              @include dark {
                content: url('../../../assets/img/icons/landing/in_progress_dark.png');
              }
              @include light {
                content: url('../../../assets/img/icons/landing/in_progress_light.png');
              }
              border-radius: 5px;
              animation: spin 1.5s linear infinite;
              @keyframes spin {
                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }
          &.disabled {
            padding-left: 30px;
          }
        }
      }
      .status {
        @include text-control(48px, 72px, 600, $text-landing-map-dark, $text-landing-map-light);
        margin-top: auto;
      }
    }
  }
}
