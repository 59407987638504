@import '../../../styles/variables';
@import '../../../styles/mixins';

.black_hole_wrapper {
  width: 100%;
  max-height: 664px;
  position: relative;
  img {
    width: 100%;
  }
  .content_wrapper {
    position: absolute;
    width: calc(100% - 21.9% - 5.8%);
    height: 95%;
    bottom: 0;
    left: 21.9%;

    @include flex(row, center, space-between, nowrap);

    .percent {
      @include text-control(64px, 96px, 600, $text-accent-dark, $text-accent-light);
    }

    .text_wrapper {
      max-width: 409px;
      .title {
        @include text-control(32px, 140%, 600, $text-accent-dark, $text-accent-light);
        margin-bottom: 20px;
      }
      .description {
        @include text-common(md);
        @include description;
      }
    }
  }
}
