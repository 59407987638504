@import 'styles/mixins';
@import 'styles/variables';

.checkbox {
  margin-top: 1px;
  width: 21px;
  height: 21px;
  min-width: 21px;
  min-height: 21px;
  position: relative;
  &__label {
    @include flex(row, flex-start, flex-start, nowrap);
    cursor: pointer;
    & + & {
      margin-top: 25px;
    }
  }
  &__text {
    margin-left: 30px;
    margin-top: 3px;
    @include text-common(smd);
    @include b('mobile') {
      margin-left: 10px;
    }
  }
  &-input {
    position: absolute;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  &-inner {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    border: $border-connect-dark;
    box-sizing: border-box;
    border-radius: 3px;
    left: 0;
    display: block;
  }
  &-checked &-inner {
    background: $bg-accent;
    border-color: $bg-accent;
    &::before {
      content: '';
      display: block;
      background-image: url(../../assets/img/icons/check.svg);
      background-repeat: no-repeat;
      background-position: 2.5px 5px;
      width: 100%;
      height: 100%;
    }
  }
}
