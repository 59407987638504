@import '../../styles/variables';
@import '../../styles/mixins/index';

.link_wrapper {
  margin-bottom: 34px;
}

.sublink_wrapper {
  margin-top: 17px;
}

.link,
.sublink {
  @include flex(row, center);
  text-decoration: none;
  font-weight: 600;
  color: $text-menu-link;
  cursor: pointer;

  .icon {
    margin-right: 12px;
  }

  .dropdown {
    margin-left: auto;
  }

  &.active,
  &:hover {
    @include dark {
      color: $text-accent-dark;
    }
    @include light {
      color: $text-accent-light;
    }

    img {
      filter: $svg-filter-color-accent;
    }
  }

  &.active {
    .dropdown {
      transition: transform 0.3s ease-in-out;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}
.sublink {
  padding-left: 25px;
}
