@import '../../../styles/variables';
@import '../../../styles/mixins';

.advertise_wrapper {
  width: 100%;
  padding: 156px 65px 153px;
  margin-bottom: 102px;
  position: relative;
  z-index: 0;
  @include flex(row, normal, space-between, nowrap);
  @include dark {
    background-color: $bg-landing-container-dark;
  }
  @include light {
    background-color: $bg-landing-container-light;
  }

  .title {
    position: relative;
    z-index: 20;
    @include text-control(32px, 48px, 600);
    max-width: 505px;
  }

  .content {
    max-width: 410px;
    .description {
      margin-bottom: 42px;
      @include description;
      @include text-common(md);
    }
    .btn {
      width: 190px;
    }
  }

  .bg_1,
  .bg_2 {
    z-index: 0;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
