@import '/src/styles/mixins';
@import '/src/styles/variables';

.wl_form {
  &__input {
    margin: 15px 0 20px;
  }
  &__text {
    @include text-common(sm);
    max-width: 545px;
  }
  &__list {
    width: 100%;
    &__item {
      width: 100%;
      @include text-common(sm);
      @include flex(row, flex-start, flex-start, nowrap);
      & + & {
        margin-top: 10px;
      }
      &::before {
        content: '';
        min-width: 5px;
        min-height: 5px;
        background: $bg-accent;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        display: block;
        margin-top: 6px;
        margin-right: 10px;
      }
      &__example_wrapper {
        width: calc(100% - 15px);
      }
      &__example {
        padding-left: 30px;
        position: relative;

        @include b('mobile') {
          padding-left: 0;
        }
        &::before {
          content: 'e.g.';
          display: block;
          position: absolute;
          left: 0;
          @include b('mobile') {
            position: static;
          }
        }
        &_address {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
