@import 'styles/variables';
@import 'styles/mixins';

.info_wrapper {
  margin-top: 35px;
}

.title {
  @include text-common(lmd, 500);
  margin-bottom: 3px;
}
.label {
  @include text-control(
    14px,
    140%,
    normal,
    $text-project-description-dark,
    $text-project-description-dark
  );
  letter-spacing: 0.03em;
}
.links {
  margin: 20px 0 10px;

  .link {
    display: inline-block;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $text-accent-dark;
    @include light {
      color: $text-accent-light;
    }
    margin-bottom: 20px;
  }
}
.info_card {
  width: 100%;
  padding: 14px 17px;
  @include box(dark-blue-card, 10px);
  @include flex(row, center);
  margin-top: 30px;
  & + & {
    margin-top: 10px;
  }

  .icon {
    width: 34px;
    height: 34px;
    margin-right: 17px;
    img {
      width: 100%;
    }
  }
  .card_value {
    @include text-common(smd, 500);
    margin-bottom: 3px;
    span {
      @include text-control(
        12px,
        140%,
        normal,
        $text-project-description-dark,
        $text-project-description-dark
      );
      letter-spacing: 0.03em;
    }
  }
  .card_label {
    @include text-control(
      12px,
      140%,
      normal,
      $text-project-description-dark,
      $text-project-description-dark
    );
    letter-spacing: 0.03em;
  }
}
