@import '../../styles/variables';
@import '../../styles/mixins';

.pagination_wrapper {
  width: 100%;
  @include flex(row, center, center);
  gap: 30px;
  margin-bottom: 30px;

  .prev, .next {
    height: 20px;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
    background-image: url(../../assets/img/icons/arrow-steps.svg);
    cursor: not-allowed;
    &.active {
      cursor: pointer;
      &:hover {
        filter: $svg-filter-color-accent;
      }
    }
  }

  .prev {
    transform: rotate(180deg);
  }
}
