.form {
  &__create_presale {
    &__row {
      @include grid(1fr 1fr, auto, 20px);
      margin-top: 48px;
      @include b('mobile') {
        @include grid(1fr, auto, 25px);
      }
      &__box {
        @include grid(1fr 1fr, auto, 10px);
        align-items: flex-end;
        height: fit-content;
        &__top {
          align-items: flex-start;
        }
      }
    }
    &__box {
      @include flex(row, center, space-between);
      margin-top: 48px;
    }
    &__text {
      color: $text-input-placeholder-dark;
      max-width: 370px;
      line-height: 20px;
    }
    &__prefix {
      margin-left: 20px;
      margin-right: -15px;
    }
    &__postfix {
      margin-right: 20px;
    }
    &__btns {
      @include flex(row, center);
      @include b('mobile') {
        @include flex(column-reverse, center, space-between, nowrap);
        width: 100%;
      }
      &__item {
        padding: 9px 38px !important;
        @include b('mobile') {
          width: 100%;
          margin-top: 15px;
        }
        & + & {
          margin-left: 15px;
          @include b('mobile') {
            margin-left: 0;
          }
        }
        &.back {
          padding: 9px 36px !important;
        }
      }
    }
    &__info {
      &__title {
        @include text-common(slg, bold);
      }
      &__text {
        max-width: 550px;
        @include text-common(md);
      }
    }
  }
}
