@import '../../styles/mixins';
@import '../../styles/variables';

$self: '.check';

.check {
  @include grid(26px 1fr, 1fr, 20px);
  padding: 20px;
  cursor: pointer;
  width: 100%;
  & + & {
    margin-top: 10px;
  }
  &__center {
    align-items: center;
  }
  &.dark {
    @include box(dark);
  }
  &.blue {
    @include box(blue);
  }
  &.dark-border {
    @include box(dark-border);
  }
  &.active,
  &:hover {
    border: 2px solid $text-accent-dark !important;
    @include light {
      border: 2px solid $text-accent-light !important;
      background: #fff;
    }
  }
  &__absolute {
    position: relative;

    display: block;
    #{$self} {
      &__item {
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
  }
  &__item {
    box-sizing: content-box;
    height: 22.8px;
    width: 22.8px;
    border: $border-connect-dark;
    @include flex(row, center, center);
    border-radius: 50%;
    @include light {
      border: $border-connect-light;
    }
  }
  &__content {
    width: 100%;
  }
  &.active &__item {
    border-color: $text-accent-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    @include light {
      border-color: $text-accent-light;
    }
    &::before {
      content: '';
      height: 13px;
      width: 13px;
      border-radius: 50%;
      background: $text-accent-dark;
      @include light {
        background: $text-accent-light;
      }
    }
  }
}
