.table {
  &__head {
    margin-bottom: 15px;
    color: $text-label-dark;
    display: grid;
    padding: 0 20px;
    @include b('tablet') {
      padding-right: 50px;
    }
    @include b('mobile') {
      padding-right: 35px;
    }
    &__name {
      padding-left: 58px;
      @include b('tablet') {
        padding-left: 48px;
      }
      @include b('mobile') {
        padding-left: 35px;
      }
    }
  }
  &__toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate3d(0, -50%, 0);
    padding: 10px;
    transition: 0.3s;
    transform-origin: center;
    display: none;
    @include b('tablet') {
      display: block;
    }
    @include b('mobile') {
      right: -10px;
      padding: 5px;
    }
    @include dark {
      color: $text-label-dark;
    }
    @include light {
      color: $text-label-light;
    }
  }
  &__item {
    padding: 20px;
    @include b('tablet') {
      padding-right: 0;
    }
    @include b('mobile') {
      padding: 15px;
    }
    &:nth-child(2n) {
      background: transparent !important;
    }
    &__content {
      display: grid;
      position: relative;

      @include b('tablet') {
        padding-right: 50px;
      }
      @include b('mobile') {
        padding-right: 20px;
      }
      &__hidden {
        padding-left: 50px;
        @include flex(row, center, flex-start, nowrap);
        @include b('mobile') {
          @include flex(column);
          padding-left: 0;
        }
      }
    }
    &__name {
      @include flex(row, center, normal, nowrap);
    }
    &__img {
      width: 42px;
      height: 42px;
      @include flex(row, center, center);
      overflow: hidden;
      border-radius: 50%;
      margin-right: 15px;
      @include b('tablet') {
        width: 34px;
        height: 34px;
      }
      @include b('mobile') {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      img {
        width: 100%;
      }
    }
    &__wrapper {
      @include flex(row, center);
      width: 100%;
      &_mobile {
        display: none;
        @include b('mobile') {
          display: flex;
        }
      }
      & + & {
        margin-left: 20px;
        @include b('mobile') {
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }
    &__element {
      width: 100%;
      max-width: 90px;
      @include b('mobile') {
        max-width: 132px;
      }
      &:only-child {
        @include b('mobile') {
          max-width: 100%;
        }
      }

      & + & {
        margin-left: 20px;
        @include b('mobile') {
          margin-left: 10px;
        }
      }
      &_mobile {
        display: none;
        @include b('mobile') {
          display: block;
        }
      }
      &__head {
        color: $text-label-dark;
        margin-bottom: 5px;
      }
    }
  }
  &__item_active &__toggle {
    transform: translate3d(0, -50%, 0) rotate(-180deg);
  }
  &__body {
    border-radius: 15px;
    overflow: hidden;
  }
  .show-enter {
    padding-top: 0;
    max-height: 0;
    opacity: 0;
  }
  .show-enter-done {
    padding-top: 20px;
  }
  .show-enter-active {
    padding-top: 20px;
    max-height: 2000px;
    opacity: 1;
    transition: padding 300ms, max-height 300ms, opacity 500ms;
  }
  .show-exit {
    padding-top: 20px;
    max-height: 2000px;
    opacity: 1;
  }
  .show-exit-active {
    padding-top: 0;
    opacity: 0;
    max-height: 0;
    transition: padding 300ms, max-height 180ms, opacity 300ms;
  }
}

.dark {
  .table {
    &__item {
      background: $item-bg-dark;
      &__name {
        color: white;
      }
    }
  }
}

.light {
  .table {
    &__item {
      background: $item-bg-light;
      &__name {
        color: $text-footer-description-light;
      }
    }
  }
}
