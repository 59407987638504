@import 'styles/mixins';
@import 'styles/variables';

.back {
  @include flex(row, center);
  width: fit-content;
  cursor: pointer;
  span {
    display: block;
    margin-left: 5px;
  }
}
.img {
  @include light {
    path {
      stroke: $text-footer-description-light;
    }
  }
}
