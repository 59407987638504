@import 'styles/mixins';
@import 'styles/variables';

$border-color: rgba(255, 255, 255, 0.5);
$border-gap: 38px;
.uploader {
  text-align: center;
  padding: 40px;
  width: 100%;
  max-width: 372px;
  display: block;
  @include box(dark, 10px);
  border: none;
  background-image: repeating-linear-gradient(
      to right,
      $border-color 0%,
      $border-color 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to right,
      $border-color 0%,
      $border-color 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $border-color 0%,
      $border-color 50%,
      transparent 50%,
      transparent 100%
    ),
    repeating-linear-gradient(
      to bottom,
      $border-color 0%,
      $border-color 50%,
      transparent 50%,
      transparent 100%
    );
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: $border-gap 1px, $border-gap 1px, 1px $border-gap, 1px $border-gap;

  &__wrapper {
    position: relative;
  }
  &__title {
    @include text-common(lmd);
    margin-top: 15px;
  }
  &__subtitle {
    margin-top: 5px;
  }
  &__preview {
    max-width: 100%;
    max-height: 100%;
  }
  &__error {
    position: absolute;
    left: 10px;
    top: calc(100% + 5px);
    color: $red;
  }
}
