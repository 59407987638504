@import 'styles/mixins';
@import 'styles/variables';

.modal_wrapper {
  width: 100%;
  max-width: 409px;
  @include b(extramobile) {
    width: 335px;
  }
}
.unable_to_vote_wrapper {
  padding: 40px 25px;

  .title {
    margin-bottom: 30px;
    text-align: center;
    @include text-control(24px, 120%, 700, $text-primary-dark, $text-primary-light);
  }

  .text {
    @include text-common(smd, 500);
    margin-bottom: 30px;
  }
}
