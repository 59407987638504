@import 'styles/variables';
@import 'styles/mixins';

.discuss_wrapper {
  margin-top: 35px;
  span {
    @include text-common(sm);
    color: $text-label-dark;
    @include light {
      color: $text-label-light;
    }
  }
}
.title {
  @include text-common(lmd, 500);
  margin-bottom: 3px;
}
.accent {
  @include text-common(sm, 600);
  color: $text-accent-dark;
  @include light {
    color: $text-accent-light;
  }
}

.comments_wrapper {
  margin-top: 20px;
  .count {
    margin-bottom: 10px;
  }

  .editor {
    margin-bottom: 15px;
    @include box(dark, 10px);
    @include light {
      color: $text-primary-light;
    }
    &::placeholder {
      color: $text-input-placeholder-dark;
      @include light {
        color: $text-input-placeholder-light;
      }
    }
  }

  .login {
    @include flex(row, center, flex-end);
    .social {
      @include flex(row, center, center);
      width: 28px;
      height: 28px;
      border-radius: 50%;
      cursor: pointer;
      margin-left: 15px;
      @include dark {
        background-color: $bg-socials-icon-dark;
      }
      @include light {
        background-color: $bg-secondary-light;
      }
      img {
        width: 50%;
      }
    }
  }

  .comments {
    margin-right: -34px;
    margin-bottom: -40px;
  }
  .comment {
    margin-bottom: 25px;
    padding-top: 5px;
    .user {
      @include flex(row);
      margin-bottom: 10px;
      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        img {
          width: 100%;
          display: block;
        }
      }
      .name {
        margin-bottom: 3px;
      }
    }

    .content {
      padding-left: 55px;
      padding-right: 80px;
      @include text-common(sm);
      a {
        font-weight: 600;
        color: $text-accent-dark;
        @include light {
          color: $text-accent-light;
        }
      }
      p {
        margin-bottom: 5px;
      }
      img {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .actions {
      margin-top: 8px;
      padding-left: 55px;
      @include text-common(sm);
      @include flex(row, center);

      *:nth-of-type(2n + 1) {
        cursor: pointer;
      }
      .replies {
        @include flex(row, center);
        .dropdown {
          width: 18px;
          height: 18px;
          transform: rotate(90deg);
          display: block;
          margin-left: 5px;
        }
      }
      .dot {
        background-color: #c4c4c4;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        margin: 0 10px;
      }
    }
  }
}
.trackY {
  width: 5px !important;
  background: #1f2149 !important;
  border-radius: 10px !important;
}
.thumbY {
  background: #525489 !important;
  width: 5px !important;
  border-radius: 10px !important;
}
