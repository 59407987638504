@import 'styles/mixins';
@import 'styles/variables';

.d_form {
  margin-top: 30px;
  @include b('mobile') {
    margin-top: 25px;
  }
  &__wrapper {
    @include grid(1fr 1fr, auto, 20px 30px);
    @include b('mobile') {
      @include grid(1fr, auto, 20px);
    }
  }
  &__input {
    &__label {
      @include text-common(sm, 600);
    }
  }
  &__btn {
    margin-left: auto;
    margin-top: 40px;
    @include b('mobile') {
      width: 100%;
    }
  }
}
