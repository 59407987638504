@import '../../styles/variables';
@import '../../styles/mixins';

$highlight-color: rgba(0, 0, 0, 0);
$bg-moon-sun-box-checked: #2e3065;
$bg-moon-sun-box: #e4eef1;

$bg-light-box-checked: #ff9900;
$bg-light-box: #dddddd;
$bg-light-knob: #ffffff;

$bg-dark-box-checked: #ffbb0b;
$bg-dark-box: #3a3a3c;
$bg-dark-knob-checked: #0f1030;
$bg-dark-knob: rgba(255, 187, 11, 0.87);

$box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
  0 3px 1px rgba(0, 0, 0, 0.03);

.switch {
  @include flex;
  position: relative;
  font-size: 0;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: $highlight-color;

  .input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &:checked + .inner {
      .box {
        &::before {
          transform: translate(21px, -50%);
        }
      }
    }
    &:checked + .moon-sun {
      .box {
        background: $bg-moon-sun-box-checked;
        &::before {
          background-image: url('../../assets/img/icons/switch_dark.svg');
        }
      }
    }
    &:checked + .light {
      .box {
        background: $bg-light-box-checked;
      }
    }
    &:checked + .dark {
      .box {
        background: $bg-dark-box-checked;
        &::before {
          background: $bg-dark-knob-checked;
          box-shadow: none;
        }
      }
    }
  }

  .inner {
    position: relative;
    display: inline-block;
    transition: all 0.2s;

    .box {
      position: relative;
      display: block;
      width: 51px;
      height: 30px;
      border-radius: 39px;
      transition: all 0.2s;
      &::before {
        content: '';
        position: absolute;
        top: calc(50%);
        left: 2px;
        width: 26px;
        height: 26px;
        transition: all 0.2s;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    &.moon-sun {
      .box {
        background: $bg-moon-sun-box;
        &::before {
          background-image: url('../../assets/img/icons/switch_light.svg');
        }
      }
    }
    &.light {
      .box {
        background: $bg-light-box;
        &::before {
          background: $bg-light-knob;
          box-shadow: $box-shadow;
        }
      }
    }
    &.dark {
      .box {
        background: $bg-dark-box;
        &::before {
          background: $bg-dark-knob;
          box-shadow: $box-shadow;
        }
      }
    }
  }
}
