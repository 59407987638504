@import 'styles/variables';
@import 'styles/mixins';

.modal_wrapper {
  max-width: 600px;
}

.tier_info_wrapper {
  padding-top: 40px;
  min-height: 338px;

  .title {
    padding: 0 30px;
    @include text-control(24px, 120%, 700);
    margin-bottom: 10px;
  }

  .description {
    padding: 0 30px;
    @include text-control(14px, 140%, 500, $text-tier-info, $text-tier-info);
    margin-bottom: 32px;
  }

  .scroll_content {
    width: 100%;
    padding: 0 30px;
    .line {
      margin-top: 7px;
      width: 1491px;
      height: 3px;
      background-color: #2c3741;
      margin-bottom: 17px;
    }

    .tier_list {
      @include flex(row, normal, normal, nowrap);

      .tier {
        position: relative;
        padding-right: 30px;
        min-width: 213px;

        .dot {
          position: absolute;
          left: 0;
          top: -25px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: $bg-accent-dark;
          @include light {
            background-color: $bg-accent-light;
          }
        }

        .level {
          @include text-control(16px, 120%, 700);
          margin-bottom: 3px;
        }

        .amount {
          @include text-common(lmd, 700);
          margin-bottom: 3px;
          color: $text-accent-dark;
          @include light {
            color: $text-accent-light;
          }
        }
        .text {
          @include text-control(12px, 140%, 500, $text-tier-info, $text-tier-info);
        }
      }
    }
  }
}
.trackX {
  height: 5px !important;
  background: #1f2149 !important;
  border-radius: 10px !important;
}
.thumbX {
  background: #525489 !important;
  height: 5px !important;
  border-radius: 10px !important;
}
