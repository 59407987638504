@import 'styles/mixins';
@import 'styles/variables';

.pi_form {
  &__upload {
    cursor: pointer;
    align-items: flex-end;
    margin: 36px 0 0;
  }
}
