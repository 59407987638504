@import "styles/mixins";
@import "styles/variables";

.l_preview {
  @include flex(row, center, space-between);
  @include b(mobile) {
    @include flex(column);
  }
  &__title {
    @include text-control(32px, 48px, 700);
    @include b(mobile) {
      @include text-control(24px, 140%, 700);
    }
  }
  &__subtitle {
    @include text-common(smd, 500);
    max-width: 645px;
    margin-top: 5px;
    @include b(mobile) {
      margin-top: 15px;
    }
    &_link {
      text-decoration: none;
      margin-left: 8px;
      @include dark {
        color: $text-accent-dark;
      }
      @include light {
        color: $text-accent-light;
      }
    }
  }
  &__box {
    max-width: 255px;
    width: 100%;
    @include box(blue);
    padding: 30px 30px 30px 60px;
    @include b(mobile) {
      max-width: none;
      margin-top: 20px;
      padding: 30px;
      text-align: center;
    }

    &__title {
      @include text-control(16px, 24px, 600);
      margin-bottom: 4px;
    }
    &__value {
      @include text-common(slg, 600);
    }
  }
}
