@import '/src/styles/mixins';
@import '/src/styles/variables';

.p_info {
  &__preview {
    width: 100%;
    max-height: 303px;
    border-radius: 20px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  &__box {
    margin-top: 35px;
    &__images {
      @include flex(row, center);
      gap: 30px;
      @include b('mobile') {
        @include flex(row, center, space-between);
        gap: 18px;
      }
    }
  }
  &__title {
    margin-bottom: 10px;
  }
  &__text {
    @include text-control(14px, 24px, normal, $text-label-dark, $text-label-light);
    p {
      margin-bottom: 24px;
    }
    h5 {
      @include text-control(14px, 24px, 600, $text-label-dark, $text-label-light);
      margin-bottom: 10px;
      font-weight: 600;
    }
  }
  &__img {
    width: 100%;
    max-height: 303px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 35px;
    img {
      width: 100%;
    }
  }
}
