@import '/src/styles/mixins';
@import '/src/styles/variables';

.modal_wrapper {
  max-width: 535px;
  width: 100%;
  @include b(extramobile) {
    width: 335px;
  }
}
.invest_wrapper {
  padding: 40px 30px;

  .title {
    margin-bottom: 30px;
    text-align: center;
    @include text-control(24px, 120%, 700, $text-primary-dark, $text-primary-light);
  }

  .input_wrapper {
    .invest_input {
      margin-bottom: 24px;
      @include b('mobile') {
        margin: 35px 0 20px;
      }
      &__title {
        @include flex(row, center, space-between);
        &__wrapper {
          @include flex(row, center, space-between);
          div + div {
            margin-left: 5px;
          }
        }
      }
      &__prefix {
        padding-right: 20px;
        @include flex(row, center, flex-start, nowrap);
        @include b('mobile') {
          padding-right: 15px;
        }
        &__img {
          border-radius: 50%;
          width: 24px;
          height: 24px;
          overflow: hidden;
          margin: 0 10px 0 15px;
          @include b('mobile') {
            margin: 0 5px 0 10px;
          }
          img {
            width: 100%;
          }
        }
        &__btn {
          border-radius: 10px;
          padding: 3px 8px;
          margin-right: 10px;
          @include text-common(smd);
          @include b('mobile') {
            @include text-common(sm);
          }
        }
        &__text {
          @include text-common(smd);
          @include b('mobile') {
            @include text-common(sm);
          }
        }
      }
    }
  }

  .invest_btns {
    margin-top: 30px;
    @include flex(row, center, flex-end);
    gap: 15px;
  }
}
