@import 'styles/variables';
@import 'styles/mixins';

.connect {
  display: block;
  margin-left: auto;
  @include b(tablet) {
    display: none;
  }
  &.mobile {
    display: none;
    margin-left: 30px;
    @include b(tablet) {
      display: block;
    }
  }
}

.after_connect_wrapper {
  margin-left: auto;
  @include flex(row, center);
  .select {
    padding: 6.4px 10px 6.5px 6.4px;
    min-width: 139px;
  }
  .wallet {
    @include flex(row, center, flex-start, nowrap);
    margin-left: 20px;
    background-color: $bg-accent-dark;
    border-radius: 29.6284px;
    padding: 7px 10px 5px 15px;
    @include light {
      background-color: $bg-accent-light;
    }
    span {
      color: $bg-main-dark;
      margin-right: 5px;
    }
    img {
      filter: $svg-filter-copy;
    }
    @include b(tablet) {
      display: none;
    }
    &.mobile {
      display: none;
      margin-left: 30px;
      @include b(tablet) {
        @include flex(row, center, flex-start, nowrap);
      }
    }
  }
}
