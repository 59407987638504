@import "styles/mixins";
@import "styles/variables";

.l_stake {
  margin-top: 120px;
  @include box(blue);
  padding: 40px 40px 50px;
  overflow: hidden;
  @include b(mobile) {
    margin-top: 70px;
    padding: 20px;
  }
  &__box {
    @include flex(row, flex-start, space-between, nowrap);
    @include b(mobile) {
      @include flex(column);
    }
    & + & {
      margin-top: 55px;
      @include b(mobile) {
        margin-top: 20px;
      }
    }
    &__item {
      width: 100%;
      & + & {
        margin-left: 30px;
        @include b(mobile) {
          margin-top: 20px;
          margin-left: 0;
        }
      }
      &__error {
        margin-top: 5px;
        margin-left: 10px;
        @include text-control(10px, 12px);
        color: $red !important;
      }
    }
  }
  &__btn {
    padding: 12px 30px !important;
    width: 100px;
    margin-top: 3px;
    margin-left: 20px;
    @include b(mobile) {
      margin-top: 20px;
    }
  }
  &__info {
    &__item {
      border-radius: 15px;
      min-height: 175px;
      padding: 30px;
      @include flex(column, center, center, nowrap);
      background: #1d1e47;

      @include light {
        background: #f9f9f9;
      }
      &__value {
        @include text-common(xxl, 600);
      }
      &__title {
        @include text-common(md, 600);
      }
      //& + & &__value {
      //  @include text-common(slg, 600);
      //}
    }
  }
  &__periods {
    &__items {
      @include grid(repeat(4, 1fr), auto, 8px);
      margin-bottom: 25px;
      @include b(extramobile) {
        display: none;
      }
    }
    &__items_sm {
      padding-bottom: 60px;
      overflow: visible;
      display: none;
      @include b(extramobile) {
        display: block;
      }

      :global(.swiper-slide) {
        //min-width: 116px !important;
      }
      :global(.swiper-slide-next) {
        opacity: 1;
      }
    }
    &__item {
      padding: 12px;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 7px;
      text-align: center;
      border: 1px solid transparent;
      @include flex(row, center, center);
      @include light {
        background: rgba(255, 255, 255, 0.08);
        border-color: #e2dff4;
      }
      @include b(mobile) {
        padding: 12px 34px;
        min-width: 56px;
        text-align: justify;
        display: inline-block;
        white-space: nowrap;
        width: 100%;
      }
      &_active {
        background: #585974;
        @include light {
          background: #d8d8d8;
        }
      }
    }
    &__content {
      &__text {
        @include text-common(smd);
        margin-top: 20px;
      }
      &__wrapper {
        @include flex(row, center, space-between, nowrap);
        @include b(mobile) {
          @include flex(column-reverse);
        }
      }
      &__info {
        @include text-common(md, 600);
        &__item {
          & + & {
            margin-top: 10px;
          }
          span + span {
            margin-left: 10px;
          }
        }
      }
      &__apr {
        width: 100%;
        max-width: 160px;
        padding: 25px;
        @include flex(column, center, center);
        text-align: center;
        border-radius: 15px;
        background: #1d1e47;
        @include b(mobile) {
          max-width: none;
          margin-bottom: 20px;
        }

        @include light {
          background: #f9f9f9;
        }

        &__title {
          @include text-common(smd);
        }

        &__value {
          margin-top: 10px;
          @include text-control(30px, 45px, 600, $text-accent-dark, $text-accent-light);
        }
      }
    }
  }
  &__unstake_data {
    @include text-control(10px, 12px);
    padding: 0 10px;
    margin-top: 6px;
  }
  &__unstake_error {
    @include text-control(10px, 12px);
    padding: 0 10px;
    margin-top: 4px;
  }
}
