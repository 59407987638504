@import '../../styles/variables';
@import '../../styles/mixins';

.input {
  border-radius: 141px;
  padding-left: 24px;
  width: 100%;
  @include flex(row, center);
  @include dark {
    border: $border-search-dark;
    color: $text-primary-dark;
  }
  @include light {
    border: $border-search-light;
  }

  &_img {
    position: relative;
    cursor: pointer;
    width: 24px;
    display: block;
    height: 24px;
    &::before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      @include dark {
        background-image: url('../../assets/img/icons/search_dark.svg');
      }
      @include light {
        background-image: url('../../assets/img/icons/search_light.svg');
      }
    }
  }
  &__header {
    @include b(tablet) {
      margin-left: 0;
      border: none !important;
      @include flex(row, center, flex-end);
    }
  }
  &__item {
    transition: all 0.2s ease-out;
    border-radius: 0;
    box-shadow: none;

    padding: 10px 20px 10px 15px;

    border: none;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 21px;
    width: calc(100% - 24px);
    background-color: transparent;
    letter-spacing: 0.035em;
    outline: none;
    &::placeholder {
      font-size: 15px;
      line-height: 20px;
    }
    @include dark {
      color: $text-primary-dark;
      &::placeholder {
        color: $text-input-placeholder-dark;
      }
    }
    @include light {
      color: $text-primary-light;
      &::placeholder {
        color: $text-input-placeholder-light;
      }
    }
    &__header {
      @include b(tablet) {
        width: 1px;
        padding: 10px 0 10px 1px;
        &:focus {
          width: calc(100% - 40px);
          opacity: 1;
          margin-left: 10px;
        }
      }
    }
  }
}
