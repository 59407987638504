@import '../../../styles/variables';
@import '../../../styles/mixins';

.quack_stats_wrapper {
  width: 100%;
  @include flex(row, flex-start, space-between, nowrap);
  margin-bottom: 38px;

  .stats,
  .token_info {
    max-width: calc((100% - 30px) / 2);
    width: 100%;
    text-transform: uppercase;

    .title {
      @include text-common(smd, 600);
      color: $text-landing-dark;
      margin-bottom: 5px;
    }
    .content {
      @include text-common(lmd, 600);
      margin-bottom: 20px;
    }
  }

  .stats {
    @include flex(row, normal, space-between, wrap);

    .stats_item {
      @include flex(column, center, center);
      max-width: calc((100% - 30px) / 2);
      width: 100%;
      padding: 24px 0 5px;
      margin-bottom: 14px;
      border-radius: 15px;
      @include dark {
        background-color: $bg-landing-dark;
      }
      @include light {
        box-shadow: $project-card-box-shadow;
      }
    }
  }

  .token_info {
    padding: 40px 39px 38px 40px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    z-index: 0;
    @include dark {
      background: linear-gradient(93.73deg, #141652 33.76%, rgba(20, 22, 82, 0) 101.08%);
    }
    @include light {
      box-shadow: $project-card-box-shadow;
    }

    h1 {
      @include text-common(xl, 600);
      text-transform: uppercase;
      margin-bottom: 27px;
    }
    .bg_1 {
      top: 3px;
      right: -92px;
    }
    .bg_2 {
      bottom: 67px;
      right: -238px;
    }
  }
}

.quack_summary_wrapper {
  width: 100%;
  @include flex(row, flex-start, space-between, nowrap);
  margin-bottom: 82px;

  .summary {
    position: relative;
    margin-top: 76px;
    //margin-left: 54px;
    max-width: 475px;
    .bg_1 {
      left: -52px;
      top: -76px;
      @include light {
        width: 321px;
        height: 218px;
        left: 75px;
        top: -53px;
      }
    }
    .bg_2 {
      top: -12px;
      right: -7px;
      @include light {
        width: 278px;
        height: 220px;
        top: 2px;
        right: -46px;
      }
    }

    .title {
      @include text-common(xxl);
      margin-bottom: 10px;
    }
    .description {
      @include text-control(14px, 24px, normal, $text-label-dark, $text-label-light);
      margin-bottom: 30px;
    }
    .btn_wrapper {
      @include flex(row, center);
      margin-bottom: 70px;
      .btn_filled {
        padding: 12px 57px;
      }
      .btn {
        border: none;
        padding: 0;
        margin-left: 22px;
        text-decoration: underline;
        &:hover {
          background: none;
          @include dark {
            color: $text-accent-dark;
          }
          @include light {
            color: $text-accent-light;
          }
        }
      }
    }

    .external_links_wrapper {
      max-width: 430px;
      width: 100%;
      @include flex(row, center, space-between);

      .link {
        .link_icon {
          path,
          ellipse,
          circle {
            transition: fill 0.4s ease;
          }
          &.poo,
          &.m,
          &.bscscan {
            @include light {
              path {
                fill: $bg-icon-light;
                stroke: $bg-main-light;
              }
              ellipse {
                fill: $bg-main-light;
              }
            }
            &:hover {
              path {
                @include dark {
                  fill: $text-accent-dark;
                }
                @include light {
                  fill: $text-accent-light;
                }
              }
            }
          }
          &.bird {
            @include light {
              path {
                fill: none;
                stroke: $bg-icon-light;
                &:nth-child(2) {
                  fill: $bg-icon-light;
                }
              }
              circle {
                fill: $bg-main-light;
              }
            }
            &:hover {
              path {
                &:nth-child(1) {
                  @include dark {
                    stroke: $text-accent-dark;
                  }
                  @include light {
                    stroke: $text-accent-light;
                  }
                }
                &:nth-child(2) {
                  @include dark {
                    fill: $text-accent-dark;
                  }
                  @include light {
                    fill: $text-accent-light;
                  }
                }
              }
            }
          }
          &.eye {
            @include light {
              path {
                fill: $bg-main-light;
                &:nth-child(2n + 1) {
                  fill: $bg-icon-light;
                }
              }
            }
            &:hover {
              path {
                &:nth-child(2n + 1) {
                  @include dark {
                    fill: $text-accent-dark;
                  }
                  @include light {
                    fill: $text-accent-light;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .content_preview_wrapper {
    max-width: 574px;
    width: 100%;
    height: 387px;
    position: relative;
    z-index: 10;
    @include dark {
      background: url('../../../assets/img/landing/landing_content_preview_dark.png') center
        no-repeat;
      background-size: 574px 387px;
    }
    @include light {
      background: url('../../../assets/img/landing/landing_content_preview_light.png') center
        no-repeat;
      background-size: 574px 387px;
    }
  }
}

.inner_relative_content {
  position: relative;
  z-index: 20;
}

.bg_1,
.bg_2 {
  position: absolute;
  z-index: 10;
  @include flex(row, center, center);
  img {
    position: absolute;
    width: 200%;
  }
}
.bg_1 {
  width: 374px;
  height: 254px;
  img {
    bottom: -100%;
    left: -50%;
  }
}
.bg_2 {
  width: 324px;
  height: 257px;
  img {
    top: -50%;
    left: -75%;
  }
}
