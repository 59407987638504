@import '../../styles/variables';
@import '../../styles/mixins';

.icon_wrapper {
  @include flex(row, center, center);
  &.big {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    img {
      display: block;
      width: 30px;
      height: 30px;
    }
    @include dark {
      background-color: #1b1c50;
    }
    @include light {
      background-color: #f4f5f8;
    }
  }
}
