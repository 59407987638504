@import '../../../styles/variables';
@import '../../../styles/mixins';

.future_dev_wrapper {
  width: 100%;
  margin-bottom: 167px;
  position: relative;
  z-index: 10;

  .title {
    @include text-common(xl, 600);
    margin-bottom: 86px;
    width: 100%;
    text-align: center;
  }
  .features_wrapper {
    @include flex(row, center, space-between, nowrap);
    padding: 0 65px;
    @include b(tablet) {
      padding: 0;
    }

    .feature_item {
      @include flex(column, center, center);
      max-width: 220px;

      .icon {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        @include flex(row, center, center);
        @include dark {
          background-color: $bg-icon-dark;
        }
        @include light {
          background-color: $bg-icon-light;
        }
      }
      .title {
        margin-top: 20px;
        margin-bottom: 8px;
        @include text-common(lmd, 600);
        text-align: center;
      }
      .description {
        @include text-common(md);
        @include description;
        text-align: center;
      }
    }
  }
}
