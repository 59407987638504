@import '../../styles/variables';
@import '../../styles/mixins';

.project_card_wrapper {
  border: 1px solid $text-accent-dark;
  width: 100%;
  max-width: 255px;
  @include flex(column);
  @include b(extramobile) {
    max-width: 100%;
  }
  background-color: $bg-project-card-dark;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
  //cursor: pointer;
  text-decoration: none;
  @include dark {
    background-color: $bg-project-card-dark;
  }
  @include light {
    background-color: $bg-project-card-light;
    box-shadow: $project-card-box-shadow;
  }

  .img_wrapper {
    position: relative;
    width: 100%;
    height: 101px;
    overflow: hidden;
    @include flex(row, center, center);

    img {
      display: block;
    }
    .project_img {
      width: 100%;
      -webkit-transform: scale(
        1.5
      ); // TODO change this later. Problem: images with small height make avatar and currency logos float under image
    }
    .status_wrapper {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  .project_content_wrapper {
    width: 100%;
    padding: 34px 15px 22px;
    position: relative;
    @include flex(column, flex-start, flex-start);

    .logos_wrapper {
      position: absolute;
      right: 58px;
      top: -19px;
      .user_avatar {
        position: absolute;
        width: 38px;
        height: 38px;
      }
      .currency {
        position: absolute;
        width: 20px;
        height: 20px;
        left: -5px;
        bottom: -45px;
      }
    }
    .access {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      margin-bottom: 7px;
      @include dark {
        color: $text-accent-dark;
      }
      @include light {
        color: $text-accent-light;
      }
    }
    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.05em;
      margin-bottom: 3px;
      min-height: 42px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @include dark {
        color: $text-primary-dark;
      }
      @include light {
        color: $text-primary-light;
      }
    }
    .description {
      text-overflow: ellipsis;
      display: -webkit-box;
      min-height: 57px;
      @include description;
      font-size: 12px;
      line-height: 160%;
      margin-bottom: 15px;
    }
    .subtitle {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.03em;
      color: $text-color-subtitle-dark;
      margin-bottom: 2px;
    }
    .raised {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 15px;
      text-transform: uppercase;
      @include dark {
        color: $text-accent-dark;
      }
      @include light {
        color: $text-accent-light;
      }
    }

    .progress_wrapper {
      width: 100%;
      .header {
        span {
          color: $text-label-dark;
          font-size: 11px;
          line-height: 16px;
        }
        span + span {
          @include font-asap;
          font-weight: 600;
          font-size: 12px;
          @include dark {
            color: $text-accent-dark;
          }
          @include light {
            color: $text-accent-light;
          }
        }
      }
      .progress {
        margin-bottom: 8px;
      }
      .progress_numbers {
        width: 100%;
        @include description;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        margin-bottom: 15px;
      }
    }

    .container {
      align-self: flex-end;
      margin-top: auto;
      @include flex(row, normal, normal, wrap);
      margin-bottom: 15px;
      .item {
        width: 50%;
        margin-bottom: 15px;
        .item_title {
          @include font-asap;
          font-size: 12px;
          line-height: 14px;
          color: $text-color-subtitle-dark;
          margin-bottom: 5px;
        }
        .item_content {
          text-transform: capitalize;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          @include dark {
            color: $text-accent-dark;
          }
          @include light {
            color: $text-accent-light;
          }
        }
      }
    }
  }
}
