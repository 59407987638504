@import '/src/styles/mixins';

.loading {
  @include flex(row, center, center);
  margin-top: 20vh;
  img {
    animation: spin 2s infinite linear;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
