@import 'styles/variables';
@import 'styles/mixins';

.tab_wrapper {
  position: relative;
  @include flex(row, normal, normal, nowrap);
  .line {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #070822;
    top: 30px;
    @include light {
      background-color: #e7e7e7;
    }
  }
  .tab {
    cursor: pointer;
    @include text-common(smd, normal);
    text-transform: capitalize;
    color: $text-label-dark;
    @include light {
      color: $text-label-light;
    }
    margin-right: 37px;
    &:last-child {
      margin-left: 0;
    }
    &.active {
      position: relative;
      color: $text-primary-dark;
      @include light {
        color: $text-primary-light;
      }
      @include text-common(smd, 600);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -10px;
        border-radius: 2px;
        height: 2px;
        @include dark {
          background-color: $bg-accent-dark;
        }
        @include light {
          background-color: $bg-accent-light;
        }
      }
    }
  }
}
.tab_content_wrapper {
  width: 100%;
}
