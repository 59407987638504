@import 'styles/mixins/';
@import 'styles/variables/';

.p_contr {
  padding-bottom: 50px;
  &__title {
    margin-top: 35px;
    margin-bottom: 5px;
  }
  &__wrapper {
    margin-top: 30px;
    max-width: 724px;
  }
  &__item {
    &__title {
      @include text-common(smd);
    }
  }
}
