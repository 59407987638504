@import '../../../styles/variables';
@import '../../../styles/mixins';

.tales_wrapper {
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 80px 0 111px;
  margin-bottom: 167px;
  @include flex(column, center, center);
  @include dark {
    background-color: $bg-tales-dark;
  }
  @include light {
    background-color: $bg-tales-light;
  }

  .logo_wrapper {
    width: 48px;
    height: 48px;
    margin-bottom: 15px;
    img {
      display: block;
      width: 100%;
    }
  }
  .title {
    @include text-common(xl, 600);
    margin-bottom: 15px;
  }
  .description {
    max-width: 729px;
    margin-bottom: 10px;
    text-align: center;
    @include description;
    @include text-common(md);
  }
  .disclaimer {
    max-width: 729px;
    text-align: center;
    @include font-asap;
    @include text-control(13px, 24px, normal, $text-accent-dark, $text-accent-light);
  }

  .bg_1,
  .bg_2 {
    position: absolute;
    top: 100%;
  }
  .bg_1 {
    left: 144px;
    max-width: 591px;
    width: 100%;
    img {
      width: 130%;
    }
  }
  .bg_2 {
    right: 119px;
    max-width: 699px;
    width: 100%;
    img {
      width: 117%;
    }
  }
}
