@import '/src/styles/mixins';
.project {
  padding-bottom: 100px;
  &__claim {
    margin: 20px 0 30px;
  }
  &__wrapper {
    @include flex();
  }
  &__content {
    @include flex(row, normal, normal, nowrap);
    margin-bottom: 100px;
    margin-top: 30px;
    @include b(mobile) {
      @include flex(column);
    }
  }
  &__box {
    max-width: 540px;
    width: 100%;
    @include b('tablet') {
      max-width: 357px;
    }
    @include b('mobile') {
      max-width: 100%;
    }
    & + & {
      margin-left: 30px;
      @include b('mobile') {
        margin-top: 30px;
        margin-left: 0;
      }
    }
  }
}
