@import '../../../styles/variables';
@import '../../../styles/mixins';

.roadmap_wrapper {
  width: 100%;
  padding: 140px 0 189px;
  margin-bottom: 107px;
  @include flex(column, center, center);
  @include dark {
    background-color: $bg-landing-container-dark;
  }
  @include light {
    background-color: $bg-landing-container-light;
  }

  .title {
    width: 100%;
    text-align: center;
    @include text-common(xl, 600);
    margin-bottom: 109px;
  }

  .roadmap_container {
    @include flex(column, flex-start);
    max-width: 707px;
    width: 100%;
    position: relative;

    .roadmap_line,
    .line1,
    .line2 {
      position: absolute;
    }
    .roadmap_line {
      .svg_wrapper {
        position: relative;
        z-index: 10;
      }
      @include light {
        svg {
          position: relative;
          z-index: 10;
          path {
            stroke: #eaeaea;
          }
        }
      }
      left: 26.8%;
      top: 39px;

      .letters {
        position: absolute;
        z-index: 0;
        @include text-control(288px, 432px, 600, $text-letters-dark, $text-letters-light);
        &.q {
          top: -145px;
          left: -14px;
          @include light {
            left: -8px;
          }
        }
        &.u {
          top: 259px;
          right: -108px;
          @include light {
            left: 5px;
          }
        }
        &.a {
          top: 663px;
          right: -111px;
          @include light {
            left: 3px;
          }
        }
        &.c {
          bottom: 245px;
          right: -111px;
          @include light {
            left: 17px;
          }
        }
        &.k {
          bottom: -158px;
          right: -147px;
          @include light {
            left: 55px;
          }
        }
      }
    }
    .line1 {
      @include light {
        display: none;
      }
      right: -200px;
      bottom: -446px;
    }
    .line2 {
      @include dark {
        display: none;
      }
      right: -360px;
      bottom: -446px;
    }

    .item {
      position: relative;
      z-index: 20;
      width: 190px;
      &:nth-child(2n) {
        width: 234px;
        align-self: flex-end;
      }
      &:nth-child(2) {
        margin-bottom: 138px;
      }
      &:nth-child(4) {
        margin-bottom: 184px;
      }
      &:nth-child(5) {
        margin-bottom: 29px;
      }
      &:nth-child(6) {
        margin-bottom: 155px;
      }
      &:nth-child(7) {
        margin-bottom: 28px;
      }
      &:nth-child(8) {
        margin-bottom: 88px;
      }
      &:nth-child(9) {
        margin-bottom: 109px;
      }
      &:nth-child(10) {
        margin-bottom: 50px;
      }

      .title {
        @include text-common(slg, 600);
        color: $text-landing-dark;
        text-align: left;
        margin-bottom: 3px;
      }
      .content {
        text-align: left;
        @include text-common(md);
        @include description;
        line-height: 140%;
        a {
          display: block;
          text-decoration: underline;
          margin-bottom: 5px;
          @include dark {
            color: $text-accent-dark;
          }
          @include light {
            color: $text-accent-light;
          }
        }
        ul {
          li {
            margin-bottom: 5px;
            @include flex(row, normal, normal, nowrap);
            &::before {
              display: inline-block;
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 50%;
              margin-right: 7px;
              margin-top: 9.5px;
              @include dark {
                background-color: $bg-accent-dark;
              }
              @include light {
                background-color: $bg-accent-light;
              }
            }
          }
        }
      }
    }
  }
}
