@import 'styles/mixins';
@import 'styles/variables';

.su_modal {
  padding: 40px 30px;
  max-width: 535px;
  width: 100%;
  @include b('mobile') {
    padding: 30px 20px;
  }
  &__title {
    @include text-common(slg, bold);
    margin-bottom: 10px;
  }
  &__input {
    margin: 50px 0;
    @include b('mobile') {
      margin: 35px 0 20px;
    }
    &__title {
      @include flex(row, center, space-between);
      &__wrapper {
        @include flex(row, center, space-between);
        div + div {
          margin-left: 5px;
        }
      }
    }
    &__prefix {
      padding-right: 20px;
      @include flex(row, center, flex-start, nowrap);
      @include b('mobile') {
        padding-right: 15px;
      }
      &__img {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        overflow: hidden;
        margin: 0 10px 0 15px;
        @include b('mobile') {
          width: 25px;
          height: 25px;
          margin: 0 5px 0 10px;
        }
        img {
          max-width: 100%;
        }
      }
      &__btn {
        @include text-common(smd);
        @include b('mobile') {
          @include text-common(sm);
        }
      }
      &__text {
        @include text-common(smd);
        @include b('mobile') {
          @include text-common(sm);
        }
      }
    }
  }
  &__btns {
    @include flex(row, center, flex-end);
    @include b('mobile') {
      @include flex(column-reverse, center, flex-start);
    }
    &__item {
      @include b('mobile') {
        margin-top: 10px;
        width: 100%;
      }
      & + & {
        margin-left: 15px;
        @include b('mobile') {
          margin-left: 0;
        }
      }
    }
  }
}
